<ng-content select=".input-group-prepend"></ng-content>
<ng-select #select [(ngModel)]="selectedValue"
           (ngModelChange)="onModelChange()"
           [id]="id ? id + '-select' : ''"
           [class]="'rounded ' + customSelectClass + ' ' + width + ' ' + styling"
           [ngClass]="{'border-invalid' : required && !selectedValue}"
           [required]="required"
           [disabled]="disabled"
           [placeholder]="nullLabel"
           [compareWith]="equalsFunction">
  <ng-option *ngIf="nullLabel" [value]="null" [attr.data-bs-display]="nullLabel">{{ nullLabel }}</ng-option>
  <ng-container *ngFor="let option of _options">
    <ng-option *ngIf="!optionVisible || isVisible(option)" [value]="option"
               [disabled]="optionSelectable && isDisabled(option)">
      {{ selectedValue === option ? selectedFormatter(option) : formatter(option) }}
    </ng-option>
  </ng-container>
</ng-select>
<ng-content select=".invalid-feedback"></ng-content>
<ng-content select=".valid-feedback"></ng-content>
