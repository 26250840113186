<div class="offcanvas-panel" (change)="setModalClosable(false)">

  <div class="modal-header">
    <div class="d-flex align-items-center header-container">
      <button app-icon-button color="muted" (click)="closeModal()"><i class="fa-regular fa-xmark"></i></button>
      <div class="ms-3">{{panelTitle}}</div>
      <div class="flex-fill"></div>
    </div>

    <div class="tab-bar">
      <div class="tab-pane">
        <ng-content select="[tabBar]"></ng-content>
      </div>
    </div>
  </div>

  <div class="modal-body">
    <div class="tab-pane">
      <ng-content></ng-content>
    </div>
  </div>

  <div *ngIf="showFooter" class="modal-footer">
    <div class="footer-container">
      <div class="footer-container-content">
        <div *ngIf="errorText" class="button-bar-text validation-errors hide-md-screen">
          {{errorText}}
        </div>
        <div *ngIf="!errorText && infoText" class="button-bar-text text-secondary hide-md-screen">
          {{infoText}}
          <i *ngIf="infoTooltip" [ngbTooltip]="infoTooltip" container="body" placement="top" class="fal fa-circle-info info-icon"></i>
        </div>
        <div class="button-bar">
          <ng-content select="[buttonBar]"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
