import {ElementRef} from '@angular/core';
import {TitleCasePipe} from '@angular/common';
import _ from 'lodash';
import {forkJoin, Observable, of, Subscriber} from 'rxjs';
import {catchError} from "rxjs/operators";
import {InjectorProvider} from './injector-provider';
import {QueryGateway, QueryOptions} from './query-gateway';
import {CommandGateway} from './command-gateway';
import {AppContext} from '../app-context';
import {environment} from '../../environments/environment';
import {HandlingModel} from "../hinterland/hinterland-utils";
import {ComparatorChain} from "./comparator-chain";
import {ConvertCamelCasePipe} from './convert-camel-case.pipe';
import {EventGateway} from './event-gateway';
import {v4 as uuidv4} from 'uuid';

export const lodash = _;

export function now() {
  return new Date().toISOString();
}

export function toCsv(array: any[]): string {
  const keys = Object.keys(array[0]);
  let result = keys.join(";") + "\n";
  array.forEach(v => result += keys.map(k => v[k]).join(";") + "\n");
  return result;
}

export function downloadFile(fileName, urlData) {
  const anchor = document.createElement('a');
  anchor.download = fileName;
  anchor.href = urlData;
  let event;
  if (typeof (Event) === 'function') {
    event = new MouseEvent('click', {bubbles: true, cancelable: true});
  } else {
    event = document.createEvent("HTMLEvents");
    event.initEvent("click", true, true);
  }
  anchor.dispatchEvent(event);
}

export function cloneObject<T, V extends T>(obj: T): V {
  return <V>lodash.cloneDeep(obj);
}

export function extractValue<T>(option: T, key: keyof T | Array<keyof T>): any {
  let result = option;
  if (key) {
    if (Array.isArray(key)) {
      for (let k of key) {
        if ((result = extractValue(option, k))) {
          return result;
        }
      }
    } else {
      const splitKey = key.toString().split('.');
      splitKey.forEach(k => result = result && result[k]);
    }
  }
  return result;
}

export function scrollToTopInElement(className: string) {
  const modalBodyElements = Array.from(document.getElementsByClassName(className));
  for (const modalBodyElement of modalBodyElements) {
    modalBodyElement.scrollTo({top: 0, behavior: 'smooth'});
  }
}

export function scrollToTopInPanel() {
  scrollToTopInElement('modal-body');
}

export function sendQuery(type: string, payload: any, options ?: QueryOptions): Observable<any> {
  if (!InjectorProvider.injector) {
    return new Observable((subscriber: Subscriber<any>) => {
      InjectorProvider.injector.get(QueryGateway).send(type, payload, options).subscribe(subscriber);
    });
  }
  return InjectorProvider.injector.get(QueryGateway).send(type, payload, options);
}

export function publishEvent(type: string, payload?: any) {
  InjectorProvider.injector.get(EventGateway).publish(type, payload || {});
}

export function sendCommand(type: string, payload: any,
                            successHandler?: (value: any) => void,
                            errorHandler?: (error: any) => void, options ?: QueryOptions) {
  AppContext.clearAlerts();
  return AppContext.waitForProcess(InjectorProvider.injector.get(CommandGateway)
    .send(type, payload, options?.customUrl, options?.sendOnlyPayload))
    .subscribe(successHandler ? successHandler : () => AppContext.registerSuccess("Operation has completed successfully"),
      errorHandler ? errorHandler : (error) => AppContext.registerError(error));
}

export function sendSameCommands(type: string, commands: any[], finishedHandler?: (results: {
  command: any,
  success: boolean,
  response: any
}[]) => void) {
  if (!finishedHandler) {
    finishedHandler = results => {
      if (results.every(r => r.success)) {
        AppContext.registerSuccess("All operations completed successfully");
      } else {
        results.filter(r => !r.success).forEach(r => AppContext.registerError(r.response));
      }
    }
  }
  const gateway = InjectorProvider.injector.get(CommandGateway);
  forkJoin(commands.map(command => AppContext.waitForProcess(gateway.send(type, command))
    .pipe(catchError(error => of({error: error})))))
    .subscribe(responses => finishedHandler(responses.map((response, index) => {
      return {
        command: commands[index],
        success: !(response && response.error),
        response: response && response.error ? response.error : response
      }
    })));
}

export interface CommandRequest {
  id: string,
  type: string,
  payload: any
}

export interface CommandResponse {
  id: string,
  success: boolean,
  value: any
}

export function sendCommands(commands: CommandRequest[], finishedHandler: (results: CommandResponse[]) => void) {
  const gateway = InjectorProvider.injector.get(CommandGateway);
  forkJoin(commands.map(command =>
    AppContext.waitForProcess(gateway.send(command.type, command.payload)).pipe(catchError(error => of({error: error})))
  ))
    .subscribe(responses => finishedHandler(responses.map((response, index) => {
      return {
        id: commands[index].id,
        success: !(response && response.error),
        value: response && response.error ? response.error : response
      }
    })));
}

export function filterByTerm(filterTerm: string, excludedFilterFields: string[] = [], searchableItems = {}): (item) => boolean {
  if (!filterTerm) {
    return () => true;
  }
  const separateTerms = filterTerm.split(' ');
  return item => {
    if ((item as unknown as HandlingModel).new) {
      return true;
    }
    let searchableItem = searchableItems[getId(item)] || makeSearchable(item, excludedFilterFields);
    return !separateTerms.some(term => searchableItem.indexOf(term.toLowerCase()) == -1);
  };
}

export function getId(item) {
  return item['handlingId'] || item['visitId'] || item['voyageId'];
}

export function makeSearchable(item: any, excludedFilterFields: string[] = []): string {
  let jsonValues = '';
  JSON.stringify(item, (key, value) => {
    if (excludedFilterFields.indexOf(key) == -1) {
      jsonValues += value + ' ';
      return value;
    }
    return undefined;
  });
  return jsonValues.toLowerCase();
}

/**
 * Checks if any of the fields in the given form element are invalid.
 */
export function checkValidity(element: HTMLElement | ElementRef<HTMLElement>, registerAppError = true): boolean {
  element = element instanceof ElementRef ? element.nativeElement : element;
  AppContext.clearAlerts();
  if (element.querySelector('.ng-invalid')) {
    element.classList.add('was-validated');
    const alert = registerAppError ? AppContext.registerError('Please review the fields with errors.') : null;
    const handler = () => {
      AppContext.closeAlerts(alert);
    };
    element.addEventListener('change', handler, {once: true});
    return false;
  }
  element.classList.remove('was-validated');
  return true;
}

export function isElementValid(element: HTMLElement | ElementRef<HTMLElement>): boolean {
  element = element instanceof ElementRef ? element.nativeElement : element;
  if (element.querySelector('.ng-invalid')) {
    element.classList.add('was-validated');
    return false;
  }
  return true;
}

export function clearValidation(elementRef: ElementRef<HTMLElement>) {
  elementRef.nativeElement.classList.remove('was-validated');
}

export function toWebsocketUrl(urlPath: string): string {
  return (window.location.protocol === 'https:' ? 'wss://' : 'ws://')
    + (environment.production ? window.location.host : "localhost:8080") + urlPath;
}

/**
 * Removes all elements from the given array for which the given callback function returns true.
 * This removes the elements in-place and returns the same array.
 * If an in-place operation is not needed, use `Array.filter` instead.
 */
export function removeIf<T>(array: T[], callbackfn: (value: T, index: number, array: T[]) => boolean): T[] {
  let i = array.length;
  while (i--) {
    if (callbackfn(array[i], i, array)) {
      array.splice(i, 1);
    }
  }
  return array;
}

/**
 * Removes the given value from the given array.
 * This removes the element in-place and returns the same array.
 * If an in-place operation is not needed, use `Array.filter` instead.
 */
export function removeItem<T>(array: T[], value: T): T[] {
  return removeIf(array, v => v === value);
}

export function replaceItem<T>(array: T[], oldValue: T, newValue: T): T[] {
  if (!oldValue) {
    array.push(newValue);
  } else {
    if (!newValue) {
      array.splice(array.indexOf(oldValue), 1);
    } else {
      array.splice(array.indexOf(oldValue), 1, newValue);
    }
  }
  return array;
}

/** Do not use  crypto.randomUUID(); - potential bugs on Mac/iOS devices -> "crypto.randomUUID() is not a function" **/
export function uuid(): string {
  return uuidv4();
}

export function dispatchChangeEvent(element: (HTMLElement | ElementRef)) {
  element = <HTMLElement>(element instanceof ElementRef ? element.nativeElement : element);
  let event;
  if (typeof (Event) === 'function') {
    event = new Event('change', {bubbles: true, cancelable: true});
  } else {
    event = document.createEvent("HTMLEvents");
    event.initEvent("change", true, true);
  }
  element.dispatchEvent(event);
}

export function toTitleCase(value: string) {
  if (!titleCasePipe) {
    titleCasePipe = InjectorProvider.injector.get(TitleCasePipe);
  }
  return value ? titleCasePipe.transform(value) : '';
}

export function convertCamelCase(value: string) {
  if (!convertCamelCasePipe) {
    convertCamelCasePipe = InjectorProvider.injector.get(ConvertCamelCasePipe);
  }
  return convertCamelCasePipe.transform(value);
}

export function sortIntoSeparateIndices(sourceArray: any[], comparator: ComparatorChain): number[] {
  let indices = Object.keys(sourceArray); //Improve to never use strings
  indices.sort(function (indexA, indexB) {
    return comparator.compare(sourceArray[indexA], sourceArray[indexB]);
  });
  return indices.map(index => parseInt(index, 10)); //Improve to never need string conversion
}

let titleCasePipe;
let convertCamelCasePipe;
