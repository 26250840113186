import { Component } from "@angular/core";

import { ConfirmationModalComponent } from "../../modals/confirmation-modal/confirmation-modal.component";
import { EditModalComponent } from "../../modals/edit-modal/edit-modal.component";
import { MainNavbarLeftComponent } from "../../navigation/main-navbar-left/main-navbar-left.component";
import { MainNavbarTopComponent } from "../../navigation/main-navbar-top/main-navbar-top.component";
import { MainBannerComponent } from "../main-banner/main-banner.component";

/**
 * This component is the main container for the new-mca pages
 * It contains the navigation bar and the main content
 * Usage:
 *     1. Add the component in the template where you want to use it.
 *     2. Use the topTitle content-input to set the title of the page.
 *     3. Use the topContentStart and topContentEnd content-inputs to set page-specific content in the top bar.
 *
 * Example:
 *    <app-main-panel>
 *        <ng-container topTitle>Page title</ng-container>
 *        <ng-container topContentStart>
 *            Page-specific text
 *        </ng-container>
 *        <ng-container topContentEnd>
 *            <button>Page-specific button</button>
 *        </ng-container>
 *    </app-main-panel>
 */
@Component({
  selector: "app-main-panel",
  templateUrl: "./main-panel.component.html",
  styleUrls: ["./main-panel.component.scss"],
  standalone: true,
  imports: [
    MainBannerComponent,
    MainNavbarTopComponent,
    MainNavbarLeftComponent,
    EditModalComponent,
    ConfirmationModalComponent,
  ],
})
export class MainPanelComponent {}
