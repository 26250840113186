import {Injectable} from '@angular/core';
import {map} from "rxjs/operators";
import {CommandRequest, sendCommand, sendCommands, sendQuery, uuid} from "../common/utils";
import {
  AttachHandling,
  CancelAndDetachHandling,
  Charter,
  CreateRoadVisit,
  CreateRoadVoyage,
  DateRange,
  DateTimeRange,
  DeclareDetachedHandling,
  DeclareHandling,
  FindDetachedHandlings,
  FindDetachedHandlingsForTerminal,
  FindDetachedHandlingsForTerminalAndDeclarant,
  FindVoyages,
  FindVoyagesForTerminal,
  FindVoyagesForTerminalAndDeclarant,
  GetDetachedHandlings,
  GetDetachedHandlingsForTerminal,
  GetDetachedHandlingsForTerminalAndDeclarant,
  GetVoyage,
  GetVoyages,
  GetVoyagesForTerminal,
  GetVoyagesForTerminalAndDeclarant,
  Handling,
  InformByMail,
  MoveHandling,
  RoadHandling,
  Terminal,
  UpgradeRoadHandling
} from "@portbase/hinterland-service-typescriptmodels";
import {AppContext} from "../app-context";
import {HinterlandUtils, VoyageUnion} from "./hinterland-utils";
import moment from "moment";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class QueryAndCommandGateway {

  constructor() {
  }

  utils = HinterlandUtils;

  public getRoadVoyages(dateRange: DateRange, maxHits: number = undefined, terminal?: Terminal): Observable<any> {
    let type = 'com.portbase.hinterland.api.common.query.';
    let payload = <GetVoyagesForTerminalAndDeclarant>{
      dateTimeRange: dateRange ? <DateTimeRange>{
        start: moment(dateRange.start).toISOString(),
        end: moment(dateRange.end).toISOString()
      } : undefined,
      modality: 'road',
      from: 0,
      maxHits: maxHits,
      excludeVisits: false,
    };
    if (AppContext.isHinterlandTerminalAndDeclarant()) {
      type += 'GetVoyagesForTerminalAndDeclarant';
      payload = <GetVoyagesForTerminalAndDeclarant>{...{excludeHandlingsFromTerminalAsDeclarant: true}, ...payload};
    } else if (AppContext.hasRole('HinterlandTerminal')) {
      type += 'GetVoyagesForTerminal';
      payload = <GetVoyagesForTerminal>{...{excludeHandlingsFromTerminalAsDeclarant: false}, ...payload};
    } else {
      type += 'GetVoyages';
      payload = <GetVoyages>{...{terminalShortname: terminal?.shortName}, ...payload};
    }

    return sendQuery(type, payload, {caching: false, showSpinner: false});
  }

  public findRoadVoyages(dateRange: DateRange, filterTerm: string, maxHits: number, terminal?: Terminal): Observable<any> {
    if (!filterTerm) {
      return this.getRoadVoyages(dateRange, maxHits, terminal);
    }

    let type = 'com.portbase.hinterland.api.common.query.';
    let payload = <FindVoyagesForTerminalAndDeclarant | FindVoyagesForTerminal | FindVoyages>{
      dateTimeRange: dateRange ? <DateTimeRange>{
        start: moment(dateRange.start).toISOString(),
        end: moment(dateRange.end).toISOString()
      } : undefined,
      term: filterTerm,
      modality: 'road',
      from: 0,
      maxHits: maxHits,
      excludeVisits: false
    };
    if (AppContext.isHinterlandTerminalAndDeclarant()) {
      type += 'FindVoyagesForTerminalAndDeclarant';
      payload = <FindVoyagesForTerminalAndDeclarant>{...{excludeHandlingsFromTerminalAsDeclarant: true}, ...payload};
    } else if (AppContext.hasRole('HinterlandTerminal')) {
      type += 'FindVoyagesForTerminal';
      payload = <FindVoyagesForTerminal>{...{excludeHandlingsFromTerminalAsDeclarant: false}, ...payload};
    } else {
      type += 'FindVoyages';
    }

    return sendQuery(type, payload, {caching: false, showSpinner: false});
  }

  public getRoadDetachedHandlings(dateRange: DateRange, excludeEmptyEta: boolean, maxHits: number = undefined): Observable<any> {
    let type = 'com.portbase.hinterland.api.common.query.';
    let payload = <GetDetachedHandlingsForTerminalAndDeclarant | GetDetachedHandlingsForTerminal | GetDetachedHandlings>{
      dateTimeRange: dateRange ? <DateTimeRange>{
        start: moment(dateRange.start).toISOString(),
        end: moment(dateRange.end).toISOString()
      } : undefined,
      excludeEmptyEta: excludeEmptyEta,
      modality: ['road'],
      maxHits: maxHits,
      from: 0
    };
    if (AppContext.isHinterlandTerminalAndDeclarant()) {
      type += 'GetDetachedHandlingsForTerminalAndDeclarant'
      payload = <GetDetachedHandlingsForTerminalAndDeclarant>{...{excludeHandlingsFromTerminalAsDeclarant: true}, ...payload};
    } else if (AppContext.isHinterlandTerminal()) {
      type += 'GetDetachedHandlingsForTerminal';
      payload = <GetDetachedHandlingsForTerminalAndDeclarant>{...{excludeHandlingsFromTerminalAsDeclarant: false}, ...payload};
    } else {
      type += 'GetDetachedHandlings'
    }

    return sendQuery(type, payload, {caching: false, showSpinner: false});
  }

  public findRoadDetachedHandlings(dateRange: DateRange, filterTerm: string, excludeEmptyEta: boolean, maxHits: number): Observable<any> {
    if (!filterTerm) {
      return this.getRoadDetachedHandlings(dateRange, excludeEmptyEta, maxHits);
    }

    let type = "com.portbase.hinterland.api.common.query."
    let payload = <FindDetachedHandlingsForTerminalAndDeclarant | FindDetachedHandlingsForTerminal | FindDetachedHandlings>{
      dateTimeRange: dateRange ? <DateTimeRange>{
        start: moment(dateRange.start).toISOString(),
        end: moment(dateRange.end).toISOString()
      } : undefined,
      term: filterTerm,
      excludeEmptyEta: excludeEmptyEta,
      excludeHandlingsFromTerminalAsDeclarant: true,
      modality: ['road'],
      from: 0,
      maxHits: maxHits
    };
    if (AppContext.isHinterlandTerminalAndDeclarant()) {
      type += 'FindDetachedHandlingsForTerminalAndDeclarant'
      payload = <FindDetachedHandlingsForTerminalAndDeclarant>{...{excludeHandlingsFromTerminalAsDeclarant: true}, ...payload};
    } else if (AppContext.isHinterlandTerminal()) {
      type += 'FindDetachedHandlingsForTerminal';
      payload = <FindDetachedHandlingsForTerminalAndDeclarant>{...{excludeHandlingsFromTerminalAsDeclarant: false}, ...payload};
    } else {
      type += 'FindDetachedHandlings'
    }

    return sendQuery(type, payload, {caching: false, showSpinner: false});
  }

  public getVoyage(voyageId: string): Observable<VoyageUnion> {
    const payload: GetVoyage = <GetVoyage>{
      voyageId: voyageId
    };
    return sendQuery("com.portbase.hinterland.api.common.query.GetVoyage", payload, {caching: false, showSpinner: false});
  }

  public upgradeRoadHandling(handling: RoadHandling, terminal: Terminal, licenseId: string, charter: Charter, eta: string, successHandler?: (value: any) => void, errorHandler?: (value: any) => void) {
    this.utils.getNextTripNumber().pipe(map(t => {
      return t
    })).subscribe(tripNumber => {
      this.utils.getNextVisitId().subscribe(visitId => {
        sendCommand('com.portbase.hinterland.api.road.command.UpgradeRoadHandling', <UpgradeRoadHandling>{
          voyageId: uuid(),
          tripNumber: tripNumber,
          visitId: visitId,
          handlingId: handling.handlingId,
          visitData: {
            modality: 'road',
            eta: eta
          },
          handlingData: handling.handlingData,
          truckLicenseId: licenseId,
          declarant: AppContext.userProfile.organisation,
          terminal: terminal,
          charter: charter,
          declarationId: undefined,
          processId: undefined
        }, successHandler, errorHandler);
      });
    });
  }

  public createRoadVoyage(handling: RoadHandling, terminal: Terminal, licenseId: string, charter: Charter, eta: string, successHandler?: (value: any) => void, errorHandler?: (value: any) => void) {
    this.utils.getNextTripNumber().subscribe(tripNumber => {
      this.utils.getNextVisitId().subscribe(visitId => {
        let payload = <CreateRoadVoyage>{
          voyageId: uuid(),
          tripNumber: tripNumber,
          visitId: visitId,
          handlingId: handling.handlingId,
          handlingData: handling.handlingData,
          visitData: {
            eta: eta,
            modality: 'road'
          },
          truckLicenseId: licenseId,
          declarant: AppContext.userProfile.organisation,
          terminal: terminal,
          charter: charter,
          declarationId: undefined,
          processId: undefined
        };
        sendCommand('com.portbase.hinterland.api.road.command.CreateRoadVoyage', payload, successHandler, errorHandler);
      });
    });
  }

  public createRoadVisit(handling: RoadHandling, terminal: Terminal, charter: Charter, voyageId: string, eta: string, successHandler?: (value: any) => void) {
    this.utils.getNextVisitId().subscribe(visitId => {
      sendCommand('com.portbase.hinterland.api.road.command.CreateRoadVisit', <CreateRoadVisit>{
        voyageId: voyageId,
        visitId: visitId,
        handlingId: handling.handlingId,
        handlingData: handling.handlingData,
        visitData: {
          eta: eta,
          modality: 'road'
        },
        declarant: AppContext.userProfile.organisation,
        terminal: terminal
      }, successHandler);
    });
  }

  public declareDetachedHandling(handling: Handling, terminal: Terminal, eta: string, successHandler?: (value: any) => void, errorHandler?: (value: any) => void) {
    sendCommand('com.portbase.hinterland.api.detached.command.DeclareDetachedHandling', <DeclareDetachedHandling>{
      terminal: terminal,
      declarant: AppContext.userProfile.organisation,
      modality: handling.handlingData.modality,
      eta: eta,
      handlingId: handling.handlingId,
      handlingData: handling.handlingData
    }, successHandler, errorHandler);
  }

  public declareHandling(handling: Handling, preNotification: boolean, voyageId: string, visitId: string, successHandler?: (value: any) => void, errorHandler?: (value: any) => void) {
    sendCommand('com.portbase.hinterland.api.common.command.DeclareHandling', <DeclareHandling>{
      voyageId: voyageId,
      visitId: visitId,
      handlingId: handling.handlingId,
      handlingData: handling.handlingData,
      preNotification: preNotification
    }, successHandler, errorHandler);
  }

  public attachHandling(handling: Handling, preNotification: boolean, nextVoyageId: string, nextVisitId: string, successHandler?: (value: any) => void, errorHandler?: (value: any) => void) {
    sendCommand('com.portbase.hinterland.api.detached.command.AttachHandling', <AttachHandling>{
      handlingId: handling.handlingId,
      nextVisitId: nextVisitId,
      nextVoyageId: nextVoyageId,
      preNotification: preNotification
    }, successHandler, errorHandler);
  }

  public moveHandling(handling: Handling, preNotification: boolean, nextVoyageId: string, nextVisitId: string, successHandler?: (value: any) => void, errorHandler?: (value: any) => void) {
    sendCommand('com.portbase.hinterland.api.common.command.MoveHandling', <MoveHandling>{
      nextVoyageId: nextVoyageId,
      nextVisitId: nextVisitId,
      handlingId: handling.handlingId,
      handlingData: handling.handlingData,
      preNotification: preNotification
    }, successHandler, errorHandler);
  }

  public detachHandlings(handlings: Handling[], responseHandler?: (value: any) => void) {
    let commandArray: Array<CommandRequest> = [];

    for (const handling of handlings) {
      commandArray.push(<CommandRequest>{
        id: handling.handlingId,
        type: 'com.portbase.hinterland.api.common.command.DetachHandling',
        payload: <CancelAndDetachHandling>{
          handlingId: handling.handlingId
        }
      });
    }
    sendCommands(commandArray, responseHandler);
  };

  public emailHandlings(handlingsIds: string[], emailAddress: string, href: string, successHandler, errorHandler) {
    sendCommand('com.portbase.common.api.reporting.InformByMail', <InformByMail>{
        handlingIds: handlingsIds,
        url: href,
        to: emailAddress
      },
      successHandler,
      errorHandler
    );
  }

}
