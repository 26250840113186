import {Component} from '@angular/core';
import {DialogComponent} from "../../dialog/dialog.component";

@Component({
  selector: 'app-log-out-confirmation-dialog',
  templateUrl: './log-out-confirmation-dialog.component.html',
  styleUrls: ['./log-out-confirmation-dialog.component.scss'],
  standalone: true,
  imports: [
    DialogComponent
  ]
})
export class LogOutConfirmationDialogComponent {

  callback: (result: boolean) => void;

  logOut() {
    this.callback(true);
  }

  dismiss() {
    this.callback(false);
  }

}
