import { NgIf, NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
} from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Placement } from '@popperjs/core';

@Component({
    selector: 'app-form-field',
    templateUrl: './form-field.component.html',
    styleUrls: ['./form-field.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgTemplateOutlet,
        NgbTooltip,
    ],
})
export class FormFieldComponent implements AfterViewInit {

  @Input() label: string;
  @Input() tooltip: string;
  @Input() tooltipPlacement: Placement = 'auto';
  @Input() renderOptional = true;
  @Input() customClass: string;

  required: boolean;

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    // Detach the Angular change detector because we are changing some values in this hook based on the rendered DOM.
    // Changing values in a hook normally triggers the "ExpressionChangedAfterItHasBeenCheckedError" error.
    // See: https://angular.io/errors/NG0100
    this.changeDetectorRef.detach();

    const inputElement = this.elementRef.nativeElement.querySelector('textarea, app-date-time, app-radio, ng-select, app-multiselect, input, select, app-toggle');
    if (inputElement) {
      const labels = this.elementRef.nativeElement.querySelectorAll('label:not(.btn)');

      if (inputElement.hasAttribute('required')) {
        this.required = true;
        labels.forEach(label => label.classList.add('required'));
      }

      if (!this.elementRef.nativeElement.querySelector('.invalid-feedback')) {
        const feedbackElement = document.createElement('div');
        inputElement.parentElement.appendChild(feedbackElement);
        feedbackElement.outerHTML = '<div class="invalid-feedback">Please enter ' + (labels ? renderLabel(labels) : 'a value') + '</div>';
      }
    }

    function renderLabel(labels: NodeList): string {
      let name = "";
      labels.forEach(node => name += node.textContent + " and ");
      name = name.slice(0, name.length - 4).toLowerCase();
      switch (name.charAt(0)) {
        case 'a':
        case 'e':
        case 'i':
        case 'o':
        case 'u':
          return 'an ' + name;
      }
      return 'a ' + name;
    }

    // Restart the change detector.
    this.changeDetectorRef.detectChanges();
    this.changeDetectorRef.reattach();
  }
}
