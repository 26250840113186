import {
  Application,
  BinnenhavengeldLanguage,
  BinnenhavengeldUserProfile,
  UserPreferences,
  UserProfile,
  Voyage
} from '@portbase/hinterland-service-typescriptmodels';
import {Alert} from './common/status-alert/alert';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import moment from "moment";
import {CommandResponse} from "./common/utils";
import {HandlingFilter} from "./hinterland/common/handling-filter/handling-filter.component";
import {FilterValues} from "./common/search/dropdown-toggle-filter/dropdown-toggle-filter.component";
import {EventEmitter} from "@angular/core";
import {InjectorProvider} from './common/injector-provider';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SortType} from "./hinterland/types";

export class AppContext {

  static roadMessages: boolean;
  static road: boolean;
  static application: Application = <Application>{};
  static userProfile: UserProfile;
  static binnenhavengeld: boolean;
  static binnenhavengeldUserProfile: BinnenhavengeldUserProfile;
  static alerts: Alert[] = [];
  static pendingProcesses: any[] = [];
  static environment: string = AppContext.detectEnvironment();
  static version: string;
  static local: boolean = !AppContext.detectEnvironment();
  static historicalViewTimestamp: string;
  static handlingFilters: HandlingFilter[] = [];
  static cachedUserPrefs: UserPreferences;
  static persistedFilterValues: FilterValues
    = (localStorage.getItem("persistedHandlingFilterValues")
    && JSON.parse(localStorage.getItem("persistedHandlingFilterValues"))) || {selected: [], deselected: []};
  static persistedFilterValuesChanged = new EventEmitter();
  static portAlertBetaUser: boolean;
  static authorizingOrganisations: string[] = [];

  static getSystemLanguage(): string {
    let result = navigator.language?.split("-")[0];
    if (result) {
      switch (result) {
        case 'en':
        case 'de':
        case 'fr':
        case 'nl':
          return result;
        default:
          return 'en';
      }
    }
    return null;
  }

  static getPreferredLanguage() {
    return localStorage.getItem('selected-language') || AppContext.getSystemLanguage() || 'en';
  }

  static getSortType(): SortType {
    return localStorage.getItem('sortType') as SortType;
  }

  static saveSortType(sortType: SortType) {
    localStorage.setItem('sortType', sortType);
  }

  static navigateToUrl(url: string) {
    InjectorProvider.injector.get(Router).navigateByUrl(url);
  }

  static navigateToBinnenhavengeldHomepage(force?: boolean) {
    if (!this.binnenhavengeldUserProfile) {
      this.navigateToUrl("/binnenhavengeld/login");
    } else {
      if (!this.binnenhavengeldUserProfile.acceptedUserAgreements?.includes(String(moment().year()))) {
        this.navigateToUrl("/binnenhavengeld/accept-terms");
      } else if (location.pathname.endsWith("binnenhavengeld") || force) {
        if (this.binnenhavengeldUserProfile.roles.includes('BinnenhavengeldAdmin')) {
          this.navigateToUrl("/binnenhavengeld/users");
        } else if (this.binnenhavengeldUserProfile.roles.includes('BinnenhavengeldSignaller')) {
          this.navigateToUrl("/binnenhavengeld/signallings");
        } else {
          this.navigateToUrl("/binnenhavengeld/subscriptions");
        }
      }
    }
  }

  static getBinnenhavengeldLanguage(): BinnenhavengeldLanguage {
    let language: BinnenhavengeldLanguage;
    switch (this.getPreferredLanguage()) {
      case 'en':
        language = 'English';
        break;
      case 'nl':
        language = 'Dutch';
        break;
      case 'de':
        language = 'German';
        break;
      case 'fr':
        language = 'French';
        break;
    }
    return language || 'English';
  }

  static getBinnenhavengeldSupportLink(): string {
    switch (AppContext.getBinnenhavengeldLanguage()) {
      case 'Dutch':
        return 'https://www.portofrotterdam.com/nl/binnenvaart/binnenhavengeld';
      case 'German':
        return 'https://www.portofrotterdam.com/de/binnenschifffahrt/binnenhafengebuehren';
      default:
        return 'https://www.portofrotterdam.com/en/inland-shipping/inland-port-dues';
    }
  }

  static isBinnenhavengeldAgent() {
    return this.binnenhavengeldUserProfile?.roles.some(r => r === 'BinnenhavengeldAgent');
  }

  static isBinnenhavengeldSuperUserOrAdmin() {
    return this.isBinnenhavengeldAdmin()
      || this.binnenhavengeldUserProfile?.roles.some(r => r === 'BinnenhavengeldSuperUser');
  }

  static isBinnenhavengeldAdmin() {
    return this.binnenhavengeldUserProfile?.roles.some(
      r => r === 'BinnenhavengeldAdmin' || r === 'HinterlandProcessAdministrator');
  }

  static isPhone(): boolean {
    return window.screen.width < 576;
  }

  static hasRole(role: string) {
    return AppContext.userProfile?.roles.includes(role);
  }

  static isOrganisationAdmin() {
    return AppContext.hasRole("orgadmin/platform/sve.platform.management.accountmanagement.orgadmin");
  }

  static isAdmin() {
    return AppContext.hasRole('HinterlandProcessAdministrator');
  }

  static isHinterlandDeclarant() {
    return AppContext.isAdmin() || AppContext.hasRole('HinterlandCargoDeclarant');
  }

  static isTractionSupplier() {
    return AppContext.isAdmin() || AppContext.hasRole('HinterlandTractionSupplier');
  }

  static isBargeDeclarant() {
    return AppContext.isAdmin() || AppContext.hasRole('bargeoperator/hinterlandcargonotification/HinterlandCargoDeclarant');
  }

  static isRoadDeclarant() {
    return AppContext.isAdmin() || AppContext.hasRole('roadhaulier/hinterlandcargonotification/HinterlandCargoDeclarant');
  }

  static isRailDeclarant() {
    return AppContext.isAdmin() || AppContext.hasRole('railoperator/hinterlandcargonotification/HinterlandCargoDeclarant');
  }

  static isHinterlandTerminal() {
    return AppContext.isAdmin() || AppContext.hasRole('HinterlandTerminal');
  }

  static isPortAlertBetaUser() {
    return AppContext.isAdmin() || AppContext.portAlertBetaUser;
  }

  static isHinterlandTerminalAndDeclarant() {
    return AppContext.hasRole('HinterlandTerminal') && AppContext.hasRole('HinterlandCargoDeclarant');
  }

  static shouldProcessBargeOrRailVoyage(voyage: Voyage) {
    return (voyage.modality == "barge" && AppContext.isBargeDeclarant()) || //
      (voyage.modality == "rail" && (AppContext.isRailDeclarant() || AppContext.isTractionSupplier()));
  }

  static waitForProcess = (process: Observable<any>): Observable<any> => {
    AppContext.pendingProcesses.push(process);
    const removeProcess = () => AppContext.pendingProcesses.splice(AppContext.pendingProcesses.indexOf(process), 1);
    return process.pipe(finalize(removeProcess));
  };

  static registerError(error: any): Alert {
    AppContext.alerts = AppContext.alerts.filter(a => a.type === 'danger');
    if (error instanceof HttpErrorResponse) {
      const errors: Array<string> = this.getHttpErrors(error);
      if (errors.length == 0) {
        return;
      }
      if (errors.length > 1) {
        errors.forEach(e => AppContext.registerError(e));
        return;
      }
      error = errors[0];
    }
    if (error instanceof Error) {
      error = error.message;
    }
    if (typeof error !== 'string') {
      error = JSON.stringify(error);
    }
    const alert = <Alert>{content: error, type: 'danger'};
    AppContext.addAlert(alert);
    return alert;
  }

  static registerSuccess(success: string) {
    const alert = <Alert>{content: success, type: 'success', msShowTime: 4000};
    AppContext.addAlert(alert);
  }

  static publishErrorMessage(message: string, title?: string) {
    const toastrService = InjectorProvider.injector.get(ToastrService);
    toastrService.toastrConfig.easeTime = 0;
    toastrService.error(message, title);
  }

  static publishValidationErrorMessage() {
    AppContext.publishErrorMessage("Please review the fields with errors.");
  }

  static publishWarningMessage(message: string, title?: string) {
    const toastrService = InjectorProvider.injector.get(ToastrService);
    toastrService.toastrConfig.easeTime = 0;
    toastrService.warning(message, title);
  }

  static publishSuccessMessage(message: string, title?: string) {
    const toastrService = InjectorProvider.injector.get(ToastrService);
    toastrService.toastrConfig.easeTime = 0;
    toastrService.toastrConfig.timeOut = 5000;
    toastrService.toastrConfig.autoDismiss = true;
    toastrService.success(message, title);
  }

  static publishInfoMessage(message: string, title?: string) {
    const toastrService = InjectorProvider.injector.get(ToastrService);
    toastrService.toastrConfig.easeTime = 0;
    toastrService.toastrConfig.timeOut = 5000;
    toastrService.toastrConfig.autoDismiss = true;
    toastrService.info(message, title);
  }

  static getHttpErrors(httpError: HttpErrorResponse): Array<string> {
    const errorMessages: Array<string> = [];
    if (String(httpError.status).startsWith('4') && !!httpError.error.error) {
      const error = httpError.error.error;
      const splitErrors = (<string>error).split('\n');
      splitErrors.forEach(e => errorMessages.push(e));
    } else {
      errorMessages.push('An unexpected error occurred (' + httpError.status + '): please contact Portbase Customer Service.')
    }
    return errorMessages;
  }

  static getHttpValidationErrors(httpError: HttpErrorResponse): Array<string> {
    const errorMessages: Array<string> = [];
    if (String(httpError.status).startsWith('4') && !!httpError.error['errorAttributes']) {
      const errors: string[] = httpError.error['errorAttributes'];
      errors.forEach(e => errorMessages.push(e));
    }
    return errorMessages;
  }

  static registerCommandResults(results: CommandResponse[], successSuffixText: string) {
    let successCount = 0;
    results.forEach(result => {
      if (result.success) {
        successCount++;
      }
    });
    if (successCount > 0) {
      AppContext.registerSuccess(`${successCount} ${successSuffixText}`);
    }
    results.forEach(result => {
      if (!result.success) {
        AppContext.registerError(result.value);
      }
    });
  }

  static addAlert(alert: Alert) {
    if (AppContext.alerts.indexOf(alert) < 0) {
      AppContext.alerts.push(alert);
    }
  }

  static closeAlerts(...alerts: Alert[]) {
    alerts.forEach(alert => {
      if (AppContext.alerts.indexOf(alert) >= 0) {
        AppContext.alerts.splice(AppContext.alerts.indexOf(alert), 1);
      }
    });
  }

  static clearAlerts() {
    AppContext.alerts = [];
  }

  static getUrlPath(): string {
    return window.location.pathname;
  }

  private static detectEnvironment(): string {
    const environmentMatch = window.location.href.match(/https:\/\/hcn\.(.*)\.portbase\.com.*/);
    if (environmentMatch) {
      return environmentMatch[1].toLowerCase();
    }
    return null;
  }

  static isHistoricalView(): boolean {
    return !!AppContext.historicalViewTimestamp;
  }

  static redirectToMessageManagement(processId) {
    AppContext.redirectToMessageManagementWithMessageId(processId, '');
  }

  static redirectToMessageManagementWithMessageId(processId, messageId) {
    const form = document.createElement('form');
    form.action = 'https://www.' + AppContext.environment + '.portbase.com/messagecontrol/searchmessage.do';
    form.method = 'POST';
    form.target = 'messagemanagement';

    const processData = {
      currentActionRouter: 'messageOverview',
      actionRouter: 'messageOverview',
      actionName: 'filterMessagesPM',
      actionValue: true,
      screenId: 'S087',
      fndMessageCreationFromDateTime: moment().add(-3650, 'days').format('DD-MM-YYYY HH:mm'),
      fndMessageCreationToDateTime: moment().add(2, 'days').format('DD-MM-YYYY HH:mm'),
      fndProcessId: processId,
      fndMessageReference: messageId,
      deeplink: true
    };
    for (const key in processData) {
      const input = document.createElement('textarea');
      input.name = key;
      input.value = typeof processData[key] === 'object' ? JSON.stringify(processData[key]) : processData[key];
      form.appendChild(input);
    }
    form.style.display = 'none';
    document.body.appendChild(form);
    form.submit();
  }

  static savePersistentFilter(filter: FilterValues) {
    AppContext.persistedFilterValues = filter;
    localStorage.setItem("persistedHandlingFilterValues", JSON.stringify(filter));
    this.persistedFilterValuesChanged.emit();
  }

  static isFiltering(filterType: PersistentFilterType) {
    return AppContext.persistedFilterValues.selected.includes(filterType);
  }

  static removePersistentFilter(filterType: PersistentFilterType) {
    if (this.isFiltering(filterType)) {
      AppContext.persistedFilterValues.selected.splice(AppContext.persistedFilterValues.selected.indexOf(filterType), 1);
      this.savePersistentFilter(AppContext.persistedFilterValues);
    }
  }

  static togglePersistentFilter(filterType: PersistentFilterType) {
    if (this.isFiltering(filterType)) {
      this.removePersistentFilter(filterType);
    } else {
      AppContext.persistedFilterValues.selected = AppContext.persistedFilterValues.selected.concat(filterType);
      AppContext.savePersistentFilter(AppContext.persistedFilterValues);
    }
  }

  static useUserflowFeatures() {
    return AppContext.environment === "accept" && AppContext.isAdmin();
  }

}

export type PersistentFilterType = "Discharge" | "Loading" | "Barge" | "Rail";
