import {Pipe, PipeTransform} from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'timestamp',
    standalone: true
})
export class TimestampPipe implements PipeTransform {

  transform(value: any, outputFormat: string = 'DD-MM-YYYY HH:mm', inputFormat:string = undefined): string {
    if (!value) {
      return value;
    } else if (inputFormat) {
      return moment(value, inputFormat).format(outputFormat);
    } else {
      return moment(value).format(outputFormat);
    }
  }

}
