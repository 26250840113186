<div class="offcanvas-panel" (change)="setModalClosable(false)">

  <div class="modal-header">
    <div class="d-flex header-container">
      <div class="d-flex centered clickable pe-3"><i class="overlay-icon fa-regular fa-xmark" (click)="closeModal()"></i></div>
      <div class="d-flex centered">Report Issue</div>
      <div class="d-flex flex-fill"></div>
    </div>
  </div>

  <div class="modal-body">
    <div class="tab-pane">
      <div class="nav-tabs-content nav-tabs pb-5">
        <div class="nav-content">

          <app-error [errors]="errors"></app-error>

          <div class="inner-section-header">Issue details</div>
          <div class="mb-4 description">Answers to the most frequently asked questions can be found on our
            <a class="link" target="_blank" href="https://support.portbase.com/services/melding-container-achterland-road/">support page <span class="fal fa-up-right-from-square"></span>.</a>
          </div>

          <div class="form-group">
            <app-form-field>
              <label>Email</label>
              <input type="text" class="form-control"
                id="emailAddresses" [(ngModel)]="emailAddress"
                required placeholder="{{emailAddress}}">
            </app-form-field>
            <app-form-field>
              <label>Issue</label>
              <textarea class="form-control" style="min-height: 15rem"
                id="feedback" [(ngModel)]="feedback"
                required placeholder="Please provide the related voyage/visit/handling ID's and/or equipment number(s) in your message, when applicable. Thank you.">
              </textarea>
            </app-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="footer-container">
      <div class="tab-pane">
        <div class="footer-container-content">
          <div *ngIf="validationErrors" class="button-bar-text validation-errors">
            Please correct the errors above and try again
          </div>
          <div *ngIf="!validationErrors" class="button-bar-text hide-md-screen">
            <i class="fa-light fa-circle-info info-icon"></i>
            A screenshot of the Road home screen will be included.
          </div>
          <div class="button-bar">
            <button appSingleClick [disableSubmit]="disableSubmit" type="button" class="btn btn-primary" (click)="reportIssue()">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
