import {NgForOf} from "@angular/common";
import {Component, Input} from '@angular/core';
import {AlertComponent} from "../../alert/alert.component";

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  standalone: true,
  imports: [
    NgForOf, AlertComponent
  ],
})
export class ErrorComponent {

  @Input() errors: string[];

}
