import {RequestGateway} from './request-gateway';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {shareReplay, tap} from 'rxjs/operators';
import {AppContext} from '../app-context';

@Injectable({
  providedIn: 'root'
})
export class QueryGateway extends RequestGateway {

  private cache: Map<string, any> = new Map();

  constructor(http: HttpClient) {
    super(http, '/query');
  }

  send<T = any>(type: string, payload: any, options?: QueryOptions): Observable<T> {
    options = options || {
      caching: true,
      showSpinner: false
    };
    let o: Observable<any>;
    if (options.caching) {
      const key = type + JSON.stringify(payload);
      const cachedResponse = this.cache.get(key);
      if (cachedResponse) {
        return cachedResponse;
      }
      this.cache.set(key, o = super.doSend(type, payload, options.customUrl, options.sendOnlyPayload).pipe(shareReplay(1)));
      o = o.pipe(tap(() => {}, () => this.cache.delete(key)));
    } else {
      o = super.doSend(type, payload, options.customUrl, options.sendOnlyPayload);
    }
    if (!options.hideError) {
      o = o.pipe(tap({error: e => AppContext.registerError(e)}));
    }
    if (options.showSpinner) {
      o = AppContext.waitForProcess(o);
    }
    return o;
  }
}

export interface QueryOptions {
  caching?: boolean;
  showSpinner?: boolean;
  hideError?: boolean;
  customUrl?: string;
  sendOnlyPayload?: boolean;
}
