import {NgIf} from "@angular/common";
import {Component, OnInit} from "@angular/core";
import {AccountingResult, DateRange} from "@portbase/hinterland-service-typescriptmodels";
import moment from "moment";

import {AppContext} from '../../../app-context';
import {DatepickerRangeComponent} from "../../../common/date-picker-range/date-picker-range";
import {downloadCsv} from '../../../common/download/download.utils';
import {HinterlandBaseComponent} from '../../../common/hinterland-base-component';
import {sendQuery} from '../../../common/utils';
import {HinterlandUtils} from '../../../hinterland/hinterland-utils';
import {
  DateRangeDialogComponent
} from "../../../new-hinterland/view-components/dialog/date-range-dialog/date-range-dialog";
import {ConfirmationModalService} from "../../modals/confirmation-modal/confirmation-modal.service";

@Component({
  selector: 'app-reference-data-offcanvas',
  templateUrl: './app-reference-data-offcanvas.html',
  styleUrls: ['./app-reference-data-offcanvas.scss'],
  standalone: true,
  imports: [NgIf, DatepickerRangeComponent]
})
export class ReferenceDataOffCanvasComponent extends HinterlandBaseComponent implements OnInit {

  data = {};

  appContext = AppContext;
  utils = HinterlandUtils;

  dateRange: DateRange;
  downloading: boolean;

  constructor(private readonly confirmationModalService: ConfirmationModalService) {
    super();
  }

  ngOnInit() {
    this.dateRange = {
      start: moment().startOf('month').format("YYYY-MM-DD"),
      end: moment().endOf('month').format("YYYY-MM-DD")
    }
  }

  openExternalUrl(url: string) {
    window.open('https://hcn.' + (AppContext.environment || 'pcs') + '.portbase.com' + url, '_blank').focus();
  }

  toggleDateRangeSelector() {
    this.confirmationModalService.openModalWithCallback((dateRange) => {
      if (dateRange) {
        this.onDateRangeChanged(dateRange);
      }
    }, DateRangeDialogComponent, {
      dateRange: this.dateRange,
      displayMonths: AppContext.isPhone() ? 1 : 2,
      title: 'Date',
      validateMonths: !AppContext.isAdmin(),
    });
  }

  onDateRangeChanged = (dateRange: DateRange): any => {
    if (dateRange != null) {
      this.dateRange = dateRange;
    }
  }

  downloadBilling() {
    this.downloading = true;
    sendQuery("com.portbase.hinterland.api.accounting.GetAccountingForRange", {
      "range": this.dateRange,
      "includeEmpty": false
    }, {
      caching: false,
      showSpinner: true
    }).subscribe((r: AccountingResult) => {
      this.downloading = false;
      const data: string[][] = [];
      data.push(['Datum', 'Handling', 'Service', 'Type', 'Container', 'Operator', 'Terminal', 'Carrier']);
      r.transactions.forEach(t => data.push([t.date, t.handlingId, t.type, t.handlingType, t.equipmentNumber, t.operator, t.terminal, t.shippingCompany]));
      downloadCsv(data, "hcn-transactions-" + this.dateRange.start + "/" + this.dateRange.end + ".csv");
    });
  }

  formatDate(date: string) {
    return moment(date).format("MMM DD, YYYY");
  }

}
