import {Pipe, PipeTransform} from '@angular/core';
import {lodash} from './utils';

@Pipe({
  name: 'convertCamelCase'
})
export class ConvertCamelCasePipe implements PipeTransform {

  transform(value: string): string {
    return value ? lodash.capitalize(lodash.startCase(value).toLowerCase()) : '';
  }

}
