import { NgClass, NgFor } from '@angular/common';
import {Component, forwardRef, Input, OnInit} from '@angular/core';
import { NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import {Observable} from 'rxjs';

import { AbstractValueAccessorComponent } from "../../../common/component/value-accessor.component";
import { extractValue, uuid } from "../../../common/utils";

@Component({
    selector: 'app-radio',
    templateUrl: './radio.component.html',
    styleUrls: ['./radio.component.css'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RadioComponent), multi: true }
    ],
    standalone: true,
    imports: [
        NgClass,
        NgFor,
        FormsModule,
    ],
})
export class RadioComponent<OptionType> extends AbstractValueAccessorComponent<OptionType> implements OnInit {

  selectedValue: OptionType;

  @Input() optionsProvider: Observable<OptionType[]>;
  @Input() options: OptionType[];
  @Input() dataKey: keyof OptionType;
  @Input() id = uuid();
  @Input() formatter: (value: OptionType) => string;
  @Input() inline: boolean;
  @Input() required;
  @Input() disabled: boolean;

  get value(): OptionType {
    return this.selectedValue;
  }

  writeValue(value: OptionType): void {
    this.selectedValue = value === undefined ? null : value;
  }

  ngOnInit(): void {
    if (this.required === "") {
      this.required = true;
    }
    if (this.optionsProvider) {
      this.optionsProvider.subscribe(values => {
        this.options = values;
        this.onOptions();
      });
    }
    if (this.options) {
      this.onOptions();
    }
    if (!this.formatter) {
      this.formatter = (value) => this.dataKey ? extractValue(value, this.dataKey) : value;
    }
  }

  private onOptions = () => {
    setTimeout(() => {
      if (this.required && this.options.length == 1) {
        this.selectedValue = this.options[0];
        this.onModelChange();
      }
    }, 0);
  };
}
