<div class="main-panel-container">
  <app-main-banner></app-main-banner>
  <app-main-navbar-top>
    <ng-content select="[topTitle]"></ng-content>
    <ng-container topContentStart>
      <ng-content select="[topContentStart]"></ng-content>
    </ng-container>
    <ng-container topContentEnd>
      <ng-content select="[topContentEnd]"></ng-content>
    </ng-container>
  </app-main-navbar-top>
  <app-main-navbar-left></app-main-navbar-left>
  <main>
    <ng-content></ng-content>
  </main>
</div>
<app-edit-modal></app-edit-modal>
<app-confirmation-modal></app-confirmation-modal>
