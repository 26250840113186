<div class="new-mca-modal modal-content">
  <div class="modal-header">
    <div>{{data['title']}}</div>
  </div>
  <div class="modal-body mt-0">
    <span class="pb-2">{{data['subtext']}}</span>

    <div class="pt-2 pe-2">
      <app-date-picker-range [onDateRangeChanged]="onDateRangeChanged" [initDateRange]="dateRange" [displayMonths]="this.displayMonths"></app-date-picker-range>
    </div>

    <span *ngIf="error" class="validation-error d-flex justify-content-end">{{error}}</span>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">Cancel</button>
    <button (click)="apply()" class="btn btn-primary" type="button">Apply</button>
  </div>
</div>
