import {EventGateway} from "./event-gateway";
import {InjectorProvider} from "./injector-provider";
import {QueryAndCommandGateway} from "../hinterland/query-and-command-gateway.service";

export abstract class HinterlandBaseComponent {

  eventGateway: EventGateway;
  queryAndCommandGateway: QueryAndCommandGateway;

  constructor() {
    this.eventGateway = InjectorProvider.injector.get(EventGateway);
    this.queryAndCommandGateway = InjectorProvider.injector.get(QueryAndCommandGateway);
    this.eventGateway.registerLocalHandler(this);
  }

}
