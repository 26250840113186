import {
  AfterViewInit,
  Component,
  ElementRef, inject,
  OnDestroy,
  OnInit,
  Type,
  ViewChild,
} from '@angular/core';
import Modal from 'bootstrap/js/dist/modal';
import { Subscription } from 'rxjs';

import { ModalDirective } from '../modal.directive';

import { ConfirmationModalService } from './confirmation-modal.service';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
  standalone: true,
  imports: [
    ModalDirective
  ]
})
export class ConfirmationModalComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('modal', {static: true}) modal: ElementRef;
  @ViewChild(ModalDirective, {static: true}) modalHost: ModalDirective;

  subscription?: Subscription;

  bootstrapModal?: Modal;

  readonly #confirmationModalService = inject(ConfirmationModalService);

  ngOnInit() {
    this.subscription = this.#confirmationModalService.modalData$.subscribe((event) => {
      if (event) {
        this.openConfirmationModal(event);
      } else {
        this.closeConfirmationModal();
      }
    });
  }

  ngAfterViewInit(): void {
    $(this.modal.nativeElement).on('hidden.bs.modal', () => this.modalHost.viewContainerRef.clear());
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private openConfirmationModal(event: ConfirmationModalEvent) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const componentRef = this.modalHost.viewContainerRef.createComponent<any>(event.component);
    componentRef.instance.data = event.data;
    componentRef.instance.callback = (close: boolean) => {
      this.#confirmationModalService.closeModal();
      if (event.callback) {
        event.callback(close);
      }
    }
    this.bootstrapModal = new Modal(this.modal.nativeElement);
    this.bootstrapModal.show();
  }

  private closeConfirmationModal() {
    this.bootstrapModal?.hide();
  }
}

export interface ConfirmationModalEvent {
  component : Type<unknown>;
  data?: object;
  callback: (result: boolean) => void;
}
