import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { PortAlertGateway } from "../gateways/port-alert.gateway";

@Injectable({
  providedIn: "root",
})
export class BetaUserService {
  constructor(private readonly gateway: PortAlertGateway) {}

  isPortAlertBetaUser(): Observable<boolean> {
    return this.gateway.query("IsBetaUser", {}).pipe(
      map((response) => response.betaUser),
      catchError(() => of(false)),
    );
  }
}
