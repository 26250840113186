<!-- @formatter:off WARNING: DO NOT FORMAT-->
<app-radio *ngIf="alternativeToggle else standardToggle" [(ngModel)]="selectedValue" [options]="options"
           (ngModelChange)="onModelChange()" [formatter]="formatter" [inline]="true"></app-radio>

<ng-template #standardToggle>
  <div *ngFor="let option of options; let idx = index; let last = last; let first = first" class="btn-group btn-group-toggle" data-bs-toggle="buttons" tabindex="0">
    <label [ngClass]="{'last-child': last, 'first-child': first}" class="btn btn-secondary">
      <input [(ngModel)]="selectedValue"
             (ngModelChange)="onModelChange()"
             [value]="option"
             [name]="id + '-options'"
             [id]="id + '-' + idx"
             type="radio">
      {{formatter(option)}}
    </label>
  </div>
</ng-template>
<!-- @formatter:on -->
