<div class="offcanvas-panel" data-bs-dismiss>

  <div class="modal-header">
    <div class="d-flex header-container">
      <div class="d-flex centered clickable pe-3" data-bs-dismiss="modal"><i
        class="d-flex overlay-icon fa-regular fa-xmark ms-2"></i></div>
      <div class="d-flex centered">Reference data</div>
      <div class="d-flex flex-fill"></div>
    </div>
  </div>

  <div class="modal-body">
    <div class="tab-pane">
      <div class="nav-tabs-content nav-tabs pb-5">
        <div *ngIf="appContext.isAdmin()" class="nav-content">
          <div class="inner-section-header">Organisation preferences</div>
          <div class="description">Edit preferences of terminals and hinterland organisations.</div>

          <button (click)="openExternalUrl('/support/organisation-preferences')" class="btn btn-secondary"
                  type="button">Organisations
            <span class="fal fa-up-right-from-square ms-1"></span></button>
          <button (click)="openExternalUrl('/support/terminals')" class="btn btn-secondary" type="button">Terminals
            <span class="fal fa-up-right-from-square ms-1"></span>
          </button>
        </div>

        <div *ngIf="appContext.isHinterlandDeclarant()" class="nav-content">
          <div class="inner-section-header">Billable transactions</div>
          <div class="date-range-selection">
            <div class="range">{{ formatDate(dateRange.start) }} - {{ formatDate(dateRange.end) }}</div>
            <div (click)="toggleDateRangeSelector()" class="fal fa-calendar-alt button selection-box"></div>
          </div>

          <div *ngIf="!downloading; else spinner">
            <button (click)="downloadBilling()" class="btn btn-secondary">Download</button>
          </div>
        </div>

        <div class="nav-content">
          <div class="inner-section-header">Reference data</div>
          <div class="description">Reference data for different modalities.</div>

          <button (click)="openExternalUrl('/refdata')" *ngIf="appContext.isAdmin()" class="btn btn-secondary"
                  type="button">Barge
            <span class="fal fa-up-right-from-square ms-1"></span></button>
          <button (click)="openExternalUrl('/support/railoperators')" *ngIf="appContext.isAdmin()"
                  class="btn btn-secondary" type="button">Rail operators
            <span class="fal fa-up-right-from-square ms-1"></span></button>
          <button (click)="utils.downloadScacs()" class="btn btn-secondary" type="button">Shipping companies <span
            class="fal fa-file-download ms-1"></span>
          </button>
        </div>

        <div *ngIf="appContext.isAdmin()" class="nav-content">
          <div class="inner-section-header">Messaging</div>
          <div class="description">Insight in current EDIFACT messaging sent and received in MCA.</div>

          <button (click)="openExternalUrl('/message-overview/road')" class="btn btn-secondary" type="button">
            Pending declarations
            <span class="fal fa-up-right-from-square ms-1"></span></button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #spinner>
  <div class="spinner">
    <div class="fa-regular fa-circle-notch fa-spin fa-4x"></div>
  </div>
</ng-template>

