import {lodash} from '../utils';

export function downloadCsv(data: string[][], fileName = 'download.csv', mimeType = 'text/csv;encoding:utf-8') {
  const content = toString(data);
  return downloadAny(content, fileName, mimeType);

  function toString(data : any[][]) : string {
    let result = '';
    data.forEach((line, index) => {
      result += line.map(item => prepareLineItem(item)).join(';');
      if (index + 1 < data.length) {
        result += '\n';
      }
    });
    return result;

    function prepareLineItem(item: any) {
      item = item ? String(item) : '';
      if (!lodash.startsWith('"', item)) {
        item = '"' + item;
      }
      if (!lodash.endsWith('"', item)) {
        item = item + '"';
      }
      return item;
    }
  }
}

export function downloadAny(content: string, fileName: string, mimeType: string) {
  const a = document.createElement('a');

if (URL && 'download' in a) { //html5 A[download]
    a.href = URL.createObjectURL(new Blob([content], {
      type: mimeType
    }));
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
  }
}
