import { NgClass, NgIf, NgFor } from '@angular/common';
import {Component, ElementRef, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import { NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { NgSelectComponent, NgSelectModule } from "@ng-select/ng-select";
import {Observable} from 'rxjs';

import {AbstractValueAccessorComponent} from '../../../common/component/value-accessor.component';
import {dispatchChangeEvent, extractValue, lodash} from '../../../common/utils';

@Component({
    selector: 'app-new-select',
    templateUrl: './new-select.component.html',
    styleUrls: ['./new-select.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => NewSelectComponent), multi: true }
    ],
    standalone: true,
    imports: [NgSelectModule, FormsModule, NgClass, NgIf, NgFor]
})
export class NewSelectComponent<OptionType> extends AbstractValueAccessorComponent<OptionType> implements OnInit {

  selectedValue;
  _refreshWatch;

  @Input() width: SelectBoxWidth = 'auto';
  @Input() styling: SelectBoxStyle = 'normal';
  @Input() customSelectClass = "";
  @Input() nullLabel = "";
  @Input() optionsProvider: Observable<OptionType[]>;
  @Input() refreshProvider;
  @Input() dataKey: keyof OptionType; // The key from which values will be compared.
  @Input() optionLabel: keyof OptionType; // The label of the <option> element.
  @Input() formatter;
  @Input() selectedFormatter;
  @Input() equalsFunction;
  @Input() id;
  @Input() disabled;
  @Input() required;
  @Input() addSelectedIfNotExists;
  @Input() autoSelectSingleOption = true;
  @Input() autoSelectFirstOption;
  @Input() optionSelectable: string | ((value) => true); // TODO: Improve typing for this option.
  @Input() optionVisible: string | ((value) => true); // TODO: Improve typing for this option.

  @ViewChild('select', {static: true}) ngSelect: NgSelectComponent

  @Input() set refreshWatch(value) {
    const changed = !lodash.isEqual(this._refreshWatch, value);
    this._refreshWatch = value === undefined ? null : value;
    if (this.optionsProvider && changed) {
      this.optionsProvider.subscribe(values => {
        this._options = values;
        this.onOptions();
      });
    }
  }

  @Input()
  get options(): OptionType[] { return this._options; }
  set options(options: OptionType[]) {
    this._options = options;
    this.onUpdate();
  }
  _options: OptionType[];

  constructor(private elementRef: ElementRef) {
    super();
  }

  ngOnInit(): void {
    if (this.refreshProvider) {
      this.optionsProvider = new Observable(subscriber => {
        const o: Observable<OptionType[]> = this.refreshProvider(this._refreshWatch);
        if (o instanceof Observable) {
          o.subscribe(subscriber);
        }
      });
    }
    if (!this.optionsProvider && !(this._options instanceof Array)) {
      throw new Error('Attribute optionsProvider or options is required for app-select component');
    }
    if (this.required === "") {
      this.required = true;
    }
    if (this.disabled === "") {
      this.disabled = true;
    }
    if (this.addSelectedIfNotExists === "") {
      this.addSelectedIfNotExists = true;
    }
    if (this.optionsProvider) {
      this.optionsProvider.subscribe(values => {
        this._options = values;
        this.onOptions();
      });
    } else if (this._options) {
      this.onOptions();
    }
    if (!this.formatter) {
      this.formatter = value => this.optionLabel ? extractValue(value, this.optionLabel) : this.dataKey
        ? extractValue(value, this.dataKey) : value;
    }
    if (!this.selectedFormatter) {
      this.selectedFormatter = this.formatter;
    }
    if (!this.equalsFunction) {
      this.equalsFunction = (option1: OptionType, option2: OptionType) => {
        if (!option1 && !option2) {
          return true;
        }
        if (!option1 || !option2) {
          return false;
        }
        return this.dataKey
          ? extractValue(option1, this.dataKey) === extractValue(option2, this.dataKey)
          : this.optionLabel ? extractValue(option1, this.optionLabel) === extractValue(option2, this.optionLabel)
            : this.formatter(option1) === this.formatter(option2);
      }
    }

  }

  writeValue(value: any): void {
    this.selectedValue = value ? value : null;
    if (this.selectedValue) {
      this.onUpdate();
    }
  }

  get value(): any {
    return this._options.find(o => this.equalsFunction(o, this.selectedValue)) || null;
  }

  private onOptions = () => {
    setTimeout(() => {
      if (!this.selectedValue) {
        if (this.required && this._options.length == 1 && this.autoSelectSingleOption
          || (this._options.length > 0 && this.autoSelectFirstOption)) {
          this.selectedValue = this._options[0];
          this.onModelChange();
          dispatchChangeEvent(this.elementRef.nativeElement);
        }
      }
    }, 0);
  };

  private onUpdate = () => {
    if (this.selectedValue && this._options && this.equalsFunction) {
      const exists = this._options.find(o => this.equalsFunction(o, this.selectedValue));
      if (!exists && this.addSelectedIfNotExists) {
        this._options.push(this.selectedValue);
      }
    }
  };

  isDisabled(option: OptionType) {
    return this.optionSelectable && !(typeof this.optionSelectable === 'string'
      ? option[<string>this.optionSelectable] : (<any>this.optionSelectable)(option));
  }

  isVisible(option: OptionType) {
    return this.optionVisible && !(typeof this.optionVisible === 'string'
      ? option[<string>this.optionVisible] : (<any>this.optionVisible)(option));
  }

}

/**
 * Represents the width options for a select box.
 *
 * @typedef {('sm' | 'md' | 'lg' | 'xl' | 'auto')} SelectBoxWidth
 */
export type SelectBoxWidth = 'sm' | 'md' | 'lg' | 'xl' | 'auto';
/**
 * Represents the style of a select box.
 * @typedef {('normal' | 'tertiary')} SelectBoxStyle
 */
export type SelectBoxStyle = 'normal' | 'tertiary';
