import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { CommandAPI, QueryAPI } from "../types/api";

type RequestMethod = "query" | "command";
type API = QueryAPI & CommandAPI;

const fluxClass = "io.fluxcapacitor.javaclient.common.Message";

interface Request<T extends keyof API> {
  "@class": typeof fluxClass;
  payload: API[T]["request"] & { "@class": T };
}

@Injectable({
  providedIn: "root",
})
export class PortAlertGateway {
  constructor(private readonly http: HttpClient) {}

  private static readonly url = "/api/proxy/portalert";

  /**
   * Sends a query to the backend.
   * Request and response types for a given query type are defined in the QueryAPI interface.
   */
  public query<T extends keyof QueryAPI>(
    type: T,
    data: QueryAPI[T]["request"],
  ) {
    return this.#request<T, QueryAPI[T]["response"]>("query", type, data);
  }

  /**
   * Sends a command to the backend.
   * Request and response types for a given command type are defined in the CommandAPI interface.
   */
  public command<T extends keyof CommandAPI>(
    type: T,
    data: CommandAPI[T]["request"],
  ) {
    return this.#request<T, CommandAPI[T]["response"]>("command", type, data);
  }

  #request<T extends keyof API, R>(
    method: RequestMethod,
    type: T,
    payloadData: API[T]["request"],
  ): Observable<R> {
    const url = `${PortAlertGateway.url}/${method}`;
    const payload = {
      "@class": `com.portbase.hinterland.portalert.api.${method}.${type}`,
      ...payloadData,
    };

    const data = {
      "@class": fluxClass,
      payload,
    } as Request<T>;

    return this.http.post<R>(url, data, { observe: "response" }).pipe(
      map((response) => {
        if (![200, 204].includes(response.status)) {
          throw new Error(`Request failed with status code ${response.status}`);
        }

        return response.body as R;
      }),
      catchError((err) => {
        if (
          err instanceof Error &&
          err.message === "Unexpected end of JSON input"
        ) {
          // The backend returned an empty response.
          return of({} as R);
        }

        throw err;
      }),
    );
  }
}
