import { NgIf, NgClass, NgTemplateOutlet } from '@angular/common';
import {Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import { NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { NgbDateParserFormatter, NgbDateStruct, NgbHighlight, NgbInputDatepicker, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import {PlacementArray} from "@ng-bootstrap/ng-bootstrap/util/positioning";
import moment, {Moment} from 'moment';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';

import { AbstractValueAccessorComponent } from "../../common/component/value-accessor.component";
import { dispatchChangeEvent } from "../../common/utils";

import {NlDateParserFormatter} from './nl-date-parser-formatter';
import { TimestampPipe } from './pipes/timestamp.pipe';

const times = [
  '00:00', '00:15', '00:30', '00:45',
  '01:00', '01:15', '01:30', '01:45',
  '02:00', '02:15', '02:30', '02:45',
  '03:00', '03:15', '03:30', '03:45',
  '04:00', '04:15', '04:30', '04:45',
  '05:00', '05:15', '05:30', '05:45',
  '06:00', '06:15', '06:30', '06:45',
  '07:00', '07:15', '07:30', '07:45',
  '08:00', '08:15', '08:30', '08:45',
  '09:00', '09:15', '09:30', '09:45',
  '10:00', '10:15', '10:30', '10:45',
  '11:00', '11:15', '11:30', '11:45',
  '12:00', '12:15', '12:30', '12:45',
  '13:00', '13:15', '13:30', '13:45',
  '14:00', '14:15', '14:30', '14:45',
  '15:00', '15:15', '15:30', '15:45',
  '16:00', '16:15', '16:30', '16:45',
  '17:00', '17:15', '17:30', '17:45',
  '18:00', '18:15', '18:30', '18:45',
  '19:00', '19:15', '19:30', '19:45',
  '20:00', '20:15', '20:30', '20:45',
  '21:00', '21:15', '21:30', '21:45',
  '22:00', '22:15', '22:30', '22:45',
  '23:00', '23:15', '23:30', '23:45'
];

@Component({
    selector: 'app-date-time',
    templateUrl: './date-time.component.html',
    styleUrls: ['./date-time.component.css'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DateTimeComponent), multi: true },
        { provide: NgbDateParserFormatter, useClass: NlDateParserFormatter }
    ],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgbHighlight, NgIf, NgClass, NgbInputDatepicker, FormsModule, NgTemplateOutlet, NgbTypeahead, TimestampPipe]
})
export class DateTimeComponent extends AbstractValueAccessorComponent<string> implements OnInit {

  timestamp: string;
  date: NgbDateStruct;
  time: string;
  startDating: NgbDateStruct;

  @Input() dateOnly;
  @Input() timeOnly;
  @Input() timeEmptyOnInit;
  @Input() disabled = false;
  @Input() required: boolean | string;
  @Input() yearSpan = 10;
  @Input() readonly;
  @Input() showClock = true;
  @Input() datePickerPlacement: PlacementArray = 'bottom-left';
  @Input() customDateClass : string;
  @Input() customTimeClass : string;

  @Input() set calendarStartDate(value: any) {
    const referenceDate = moment(value);
    this.startDating = {year: referenceDate.year(), month: referenceDate.month() + 1, day: referenceDate.date()};
  }

  @Output() focus = new EventEmitter();

  constructor(private elementRef: ElementRef) {
    super();
  }

  get calendarStartDate() {
    if (this.date) return this.date;
    return this.startDating;
  }

  searchTime = (text$: Observable<string>) => text$.pipe(debounceTime(0), distinctUntilChanged(),
    map(term => term.length < 1 ? [] : times.filter(option =>
      option.replace(':', '').startsWith(term.replace(':', ''))).slice(0, 8)));

  onDateChange = () => {
    this.onModelChange();
  };

  onTimeChange = () => {
    this.onModelChange();
    dispatchChangeEvent(this.elementRef.nativeElement);
  };

  get value(): string {
    if (this.timeOnly) {
      return this.time;
    }
    if (!this.date || typeof this.date === 'string') {
      this.date = null;
      this.time = null;
      return null;
    }
    if (this.dateOnly) {
      const m = moment({y: this.date.year, M: this.date.month - 1, d: this.date.day});
      if (!this.isValid(m)) {
        this.date = null;
        this.time = null;
        return null;
      }
      return m.format('YYYY-MM-DD');
    }
    if (!this.time) {
      return null;
    }
    const [hours, minutes] = this.time.split(':');
    const m = moment({
      y: this.date.year,
      M: this.date.month - 1,
      d: this.date.day,
      h: Number(hours),
      m: Number(minutes)
    });
    if (!this.isValid(m)) {
      this.date = null;
      this.time = null;
      return null;
    }
    return this.timestamp = m.utc().toISOString();
  }

  writeValue(value): void {
    this.timestamp = value === undefined || value === "null" ? null : value;
    if (this.timeOnly && !!this.timestamp) {
      const withoutSeconds = this.timestamp.substring(0, this.timestamp.indexOf(":") + 3);
      this.time = withoutSeconds.match(/^(2[0-3]|[0-1]?[\d]):[0-5][\d]$/) ? withoutSeconds : null;
    } else {
      const m = moment(this.timestamp);
      this.date = m.isValid() ? {year: m.year(), month: m.month() + 1, day: m.date()} : null;
      this.time = m.isValid() && !this.timeEmptyOnInit ? m.format('HH:mm') : null;
    }
  }

  ngOnInit(): void {
    if (this.required === '') {
      this.required = true;
    }
    if (this.readonly === '') {
      this.readonly = true;
    }
  }

  private isValid = (m: Moment) => m.isValid() && m.isBetween(moment().subtract(this.yearSpan, 'years'),
    moment().add(this.yearSpan, 'years'));

}
