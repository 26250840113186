<a
  class="nav-link nav-button"
  [routerLink]="href"
  [routerLinkActiveOptions]="{ exact: true }"
  routerLinkActive="active"
  [target]="external ? 'blank' : '_self'"
>
  <span>
    <span class="nav-icon nav-element">
      <span class="fal p-2" [class]="icon"></span>
    </span>
    <span class="nav-text">
      <ng-content></ng-content>
    </span>
  </span>
  <span *ngIf="external" class="fal fa-up-right-from-square link-icon"></span>
</a>
