import { Component, HostBinding, Input } from '@angular/core';

export type IconButtonColor = 'regular' | 'dangerous' | 'muted'

@Component({
  selector: 'button[app-icon-button]',
  template: '<ng-content></ng-content>',
  styleUrl: './icon-button.component.scss',
  standalone: true
})
export class IconButtonComponent {

  @Input() color: IconButtonColor = 'regular';

  @HostBinding() role = 'button';
  @HostBinding() type = 'button';

  @HostBinding('class')
  get buttonClass(): string[] {
    return [this.color];
  }
}
