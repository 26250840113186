import {Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Subject} from "rxjs";

@Directive({
    selector: 'button[appSingleClick]',
    standalone: true
})
export class SingleClickDirective implements OnDestroy, OnInit {

  @Input()
  disableSubmit: Subject<boolean>

  constructor(private renderer: Renderer2, private el: ElementRef) {
  }

  @HostListener('click')
  onClick() {
    this.disableSubmit.next(true);
  }

  ngOnInit(): void {
    this.disableSubmit.subscribe(disabled => {
      if (disabled) {
        this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'true')
      } else {
        this.renderer.removeAttribute(this.el.nativeElement, 'disabled')
      }
    })
  }

  ngOnDestroy(): void {
    this.disableSubmit.unsubscribe();
  }
}
