<div class="alert-container">
  <div class="icon">
    @switch (color) {
      @case ('success') {
        <span class="fal fa-check-circle"></span>
      }
      @case ('warning') {
        <span class="fal fa-exclamation-circle"></span>
      }
      @case ('error') {
        <span class="fal fa-times-circle"></span>
      }
      @case ('info') {
        <span class="fal fa-info-circle"></span>
      }
    }
  </div>
  <div class="text-container">
    <div class="alert-title">{{ alertTitle }}</div>
    <div class="alert-content">{{ alertMessage }}</div>
  </div>
  @if (!embedded) {
    <div class="close">
      <button
        app-icon-button
        class="alert-close-button"
        color="muted"
        (click)="dismissAction.emit()"
      >
        <span class="fal fa-xmark"></span>
      </button>
    </div>
  }
</div>
