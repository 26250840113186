import { NgIf, NgFor, NgClass } from '@angular/common';
import {Component, forwardRef, Input, OnInit} from '@angular/core';
import { NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import {Observable} from "rxjs";

import {AbstractValueAccessorComponent} from '../../../common/component/value-accessor.component';
import {extractValue, uuid} from "../../../common/utils";
import { RadioComponent } from '../radio/radio.component';

@Component({
    selector: 'app-toggle',
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.css'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ToggleComponent), multi: true }
    ],
    standalone: true,
    imports: [
        NgIf,
        RadioComponent,
        FormsModule,
        NgFor,
        NgClass,
    ],
})
export class ToggleComponent<OptionType> extends AbstractValueAccessorComponent<OptionType> implements OnInit {

  @Input() optionsProvider: Observable<OptionType[]>;
  @Input() options: OptionType[];
  @Input() dataKey: keyof OptionType;
  @Input() id: string = uuid()
  @Input() formatter: (value: OptionType) => string;
  @Input() required: boolean;

  selectedValue: OptionType;
  alternativeToggle = false;

  get value(): OptionType {
    return this.selectedValue;
  }

  writeValue(value: OptionType): void {
    this.selectedValue = value === undefined ? null : value;
  }

  ngOnInit(): void {
    if (navigator.userAgent.indexOf("Firefox") > 0) {
      this.alternativeToggle = true;
    }

    if (this.optionsProvider) {
      this.optionsProvider.subscribe(values => {
        this.options = values;
        this.onOptions();
      });
    }
    if (this.options) {
      this.onOptions();
    }
    if (!this.formatter) {
      this.formatter = (value) => this.dataKey ? extractValue(value, this.dataKey) : value;
    }
  }

  private onOptions = () => {
    setTimeout(() => {
      if (this.required && this.options.length === 1) {
        this.selectedValue = this.options[0];
        this.onModelChange();
      }
    }, 0);
  };

}
