<div class="dialog-container" role="dialog">
  <div class="dialog-header">
    @if (color === 'dangerous') {
      <span><i class="fal fa-triangle-exclamation dialog-icon"></i></span>
    }
    {{ dialogTitle }}
  </div>
  <div class="dialog-content" [innerHtml]="dialogMessage"></div>
  <div class="dialog-footer">
    @if (secondaryActionLabel) {
      <button app-button design="secondary" (click)="secondaryAction.emit()">
        {{ secondaryActionLabel }}
      </button>
    }
    <button app-button [color]="color" (click)="primaryAction.emit()">
      {{ primaryActionLabel }}
    </button>
  </div>
  <button
    app-icon-button
    class="dialog-close-button"
    color="muted"
    (click)="dismissAction.emit()">
    <span aria-hidden="true"><i class="fal fa-xmark"></i></span>
  </button>
</div>
