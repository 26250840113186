import {RequestGateway} from './request-gateway';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommandGateway extends RequestGateway {
  constructor(http: HttpClient) {
    super(http, "/command");
  }

  send(type: string, message: any, customUrl?: string, sendOnlyPayload? : boolean): Observable<any> {
    return super.doSend(type, message, customUrl, sendOnlyPayload);
  }
}
