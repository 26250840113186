<nav class="primary-menu h-100 prevent-select">
  <div class="sidebar-sticky d-flex flex-column h-100">
    <div
      class="nav-item-header sidebar-heading d-flex justify-content-between align-items-center ps-4"
    >
      Services
    </div>
    <div class="d-flex flex-column justify-content-between h-100">
      <ul class="nav flex-column">
        <li app-main-navbar-left-item href="/road" icon="fa-truck-container">
          Road
        </li>
        <li
          [external]="true"
          app-main-navbar-left-item
          href="/shipments"
          icon="fa-boxes-stacked">
          Shipments
        </li>
        <li
          *ngIf="appContext.isAdmin()"
          [external]="true"
          app-main-navbar-left-item
          href="/message-overview/road"
          icon="fa-clock">
          Pending
        </li>
        <li
          *ngIf="appContext.isPortAlertBetaUser()"
          app-main-navbar-left-item
          href="/port-alert"
          icon="fa-siren-on">
          Port Alert
        </li>
        @if (appContext.isAdmin()) {
        <li
          app-main-navbar-left-item
          icon="fa-timeline-arrow"
          href="/port-alert/timeslots"
        >
          Time Slots
        </li>
        }
      </ul>
    </div>
  </div>
</nav>
