<ng-template #timeResultTemplate let-r="result">
  <ngb-highlight [result]="r"></ngb-highlight>
</ng-template>
<div class="notranslate fieldset" [ngClass]="{'disabled' :disabled}" *ngIf="!readonly && !timeOnly">
  <div class="form-row datetime">
    <div [class]="'col input-group date-field ' + customDateClass" *ngIf="!timeOnly">
      <input [(ngModel)]="date" (ngModelChange)="onDateChange()" [ngModelOptions]="{updateOn: 'blur'}"
             ngbDatepicker #picker="ngbDatepicker" [startDate]="calendarStartDate" [placement]="datePickerPlacement"
             [required]="required"
             (focus)="this.focus.emit($event)"
             autocomplete="off" class="form-control" placeholder="dd-mm-yyyy">
      <span class="input-group-text fal fa-calendar-alt" (click)="picker.toggle()"
                (focus)="this.focus.emit($event)" type="button" tabIndex="-1" style="z-index: auto"></span>
    </div>
    <div [class]="'col col-4 time-field ' + customTimeClass" *ngIf="!dateOnly">
      <ng-container [ngTemplateOutlet]="ti"></ng-container>
    </div>
  </div>
</div>
<ng-container [ngTemplateOutlet]="ro" *ngIf="readonly"></ng-container>
<ng-container [ngTemplateOutlet]="ti" *ngIf="!readonly && timeOnly" ></ng-container>

<ng-template #ti>
  <input [(ngModel)]="time" pattern="^([0-1][0-9]|[2][0-3]):([0-5][0-9])$"
         [ngbTypeahead]="searchTime" placeholder="hh:mm"
         [resultTemplate]="timeResultTemplate" (blur)="onTimeChange()"
         [required]="required" (focus)="this.focus.emit($event)"
         [disabled]="disabled"
         autocomplete="off" type="text" class="form-control no-spin">
</ng-template>

<ng-template #ro>
  <ng-container *ngIf="!!timestamp">
    <span [ngClass]="{'me-2 fa fa-clock text-secondary': showClock}"></span> <span [ngClass]="{'text-secondary' : !dateOnly}">{{timestamp | timestamp:'DD MMM [’]YY'}}</span>
    <span class="ms-1" *ngIf="!dateOnly">{{timestamp | timestamp:'HH:mm'}}</span>
  </ng-container>
  <input *ngIf="!timestamp" autocomplete="off" type="text" class="form-control no-spin" disabled>
</ng-template>
