import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {AppContext} from '../app-context';
import moment from 'moment';
import _ from 'lodash';

export abstract class RequestGateway {
  static baseUrl = '/api'

  private handlers = [];

  protected constructor(private http: HttpClient, private httpEndpoint: string) {
  }

  protected doSend(type: string, message: any, customUrl?: string, sendOnlyPayload? : boolean): Observable<any> {
    const handler = this.handlers.find(h => h[type]);
    if (handler) {
      console.debug("sending request to local handler", type, message);
      try {
        const result = handler[type](getPayload(message));
        return result instanceof Observable ? result : of(result);
      } catch (e) {
        return new Observable(subscriber => subscriber.error(e));
      }
    } else {
      console.debug("sending http request", type, message);
      if (sendOnlyPayload) {
        message = _.merge({'@class': type}, message);
      } else {
        message = asMessage(message, type);
        message.metadata.frontendVersion = AppContext.version;
        message.metadata.preferredLanguage = AppContext.getPreferredLanguage();
        message.metadata.localTime = moment().toLocaleString();
        message.metadata.user = AppContext.binnenhavengeld
          ? AppContext.binnenhavengeldUserProfile?.userId : AppContext.userProfile?.userName;
        message.metadata.organisation = AppContext.binnenhavengeld
          ? AppContext.binnenhavengeldUserProfile?.organisationId : AppContext.userProfile?.organisationShortName;
      }
      return this.http.post<any>(RequestGateway.baseUrl + (customUrl || this.httpEndpoint), message);
    }
  }

  registerLocalHandler(handler) {
    this.handlers.push(handler);
    return () => this.handlers = this.handlers.filter(h => h !== handler);
  }

  registerLocalHandlers(...handlers) {
    const subscriptions: any[] = handlers.map(h => this.registerLocalHandler(h));
    return () => subscriptions.forEach(s => s());
  }
}

function getPayload(message: any) {
  return message['@class'] === 'io.fluxcapacitor.javaclient.common.Message' ? message.payload : message;
}

function asMessage(payload: any, type: string) {
  //payload is already a message
  if (payload['@class'] === 'io.fluxcapacitor.javaclient.common.Message') {
    payload.metadata = payload.metadata || {};
    payload.payload["@class"] = type;
    return payload;
  }

  //payload should be converted to a message
  payload["@class"] = type;
  return {
    "@class": 'io.fluxcapacitor.javaclient.common.Message',
    payload: payload,
    metadata: {}
  }
}
