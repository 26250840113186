import { Injectable, Type } from '@angular/core';
import { Subject, throttleTime } from 'rxjs';

import {
  DefaultConfirmationDialogComponent
} from '../../dialogs/default-confirmation-dialog/default-confirmation-dialog.component';

import { ConfirmationModalEvent } from './confirmation-modal.component';

/**
 * Service to open and close the confirmation modal
 * Usage:
 *     1. Inject the service in the component where you want to open the modal.
 *     2. Call the openModal method with the ConfirmationModalEvent as parameter.
 *     3. The modal will open with the component specified in the ConfirmationModalEvent.
 *
 * NOTE: The component specified in the ConfirmationModalEvent must be declared in the module where the modal is used.
 */
@Injectable({
    providedIn: 'root'
})
export class ConfirmationModalService {

  private modalData = new Subject<ConfirmationModalEvent | null>();
  modalData$ = this.modalData.pipe(throttleTime(100));

  /**
   * Opens a confirmation modal with the specified component and optional input data.
   */
  openModal(
      component: Type<unknown> = DefaultConfirmationDialogComponent,
      data?: object
  ) {
    this.modalData.next({ component, data, callback: null });
  }

  /**
   * Opens a confirmation modal with the specified callback, component optional input data.
   */
  openModalWithCallback(
      callback: (result: any) => void,
      component: Type<unknown> = DefaultConfirmationDialogComponent,
      data?: object
  ) {
    this.modalData.next({ component, data, callback });
  }

  closeModal() {
    this.modalData.next(null);
  }

}
