import {Component, OnInit, Type} from "@angular/core";
import {FormsModule} from '@angular/forms';
import {UpdateCurrentUserPreferences, UserPreferences} from "@portbase/hinterland-service-typescriptmodels";

import {AppContext} from '../../../app-context';
import {HinterlandBaseComponent} from '../../../common/hinterland-base-component';
import {sendCommand} from '../../../common/utils';
import {CloseEditingDialogComponent} from '../../dialogs/close-editing-dialog/close-editing-dialog.component';
import {ErrorComponent} from '../../errors/error/error.component';
import {EditModalService} from '../../modals/edit-modal/edit-modal.service';
import {OffcanvasComponent} from '../../offcanvas/offcanvas.component';
import {FormFieldComponent} from "../../user-forms/form-field/form-field.component";
import {ClosableModal} from "../../modals/edit-modal/edit-modal.component";
import {ButtonComponent} from "../../button/button.component";

@Component({
  selector: 'app-notification-settings-offcanvas',
  templateUrl: './app-notification-settings-offcanvas.html',
  styleUrls: ['./app-notification-settings-offcanvas.scss'],
  standalone: true,
  imports: [
    ErrorComponent,
    FormsModule,
    OffcanvasComponent,
    ButtonComponent,
    FormFieldComponent,
  ],
})
export class NotificationSettingsOffCanvasComponent extends HinterlandBaseComponent implements OnInit, ClosableModal {
  data = {};

  isModalClosable: boolean;
  errors: string[];
  userPreferences : UserPreferences;

  constructor(
      private readonly editModalService: EditModalService,
  ) {
    super();
  }

  ngOnInit() {
    this.errors = [];
    this.userPreferences = { ...AppContext.cachedUserPrefs };
  }

  setModalClosable(closable: boolean) {
    this.isModalClosable = closable;
  }

  closeModal() {
    this.isModalClosable = true;
    this.editModalService.closeModal();
  }

  switchModal() {
    this.closeModal();
  }

  getCloseDialogComponentType(): Type<unknown> {
    return CloseEditingDialogComponent;
  }

  updateNotificationSettings() {
    sendCommand('com.portbase.common.api.authentication.command.UpdateCurrentUserPreferences', <UpdateCurrentUserPreferences> {
      preferences: this.userPreferences
    }, () => {
      AppContext.publishSuccessMessage('Preferences updated successfully');
      AppContext.cachedUserPrefs = this.userPreferences;
      this.closeModal();
    },(error) => {
      this.errors.push(error?.error?.error);
    });
  }

}
