<nav class="navbar navbar-light bg-white p-0 prevent-select">
  <div class="navbar-col col p-0 page-title">
    <div
      class="d-flex h-100 align-items-center justify-content-between text-secondary px-3"
    >
      <div class="col-auto navbar-title">
        <ng-content></ng-content>
      </div>
      <div class="hide-md-screen inserted-content">
        <div>
          <ng-content select="[topContentStart]"></ng-content>
        </div>
        <div>
          <ng-content select="[topContentEnd]"></ng-content>
        </div>
      </div>
    </div>
  </div>

  <!-- right side: icons -->
  <div
    class="navbar-col d-flex justify-content-end align-items-center user-icon"
  >
    <button
      (click)="editNotificationSettings()"
      app-icon-button
      class="mx-1 hide-md-screen"
      color="muted"
      tabindex="0"
      title="Edit notification settings"
    >
      <i class="fa-light fa-bell"></i>
    </button>

    <div class="hide-md-screen">
      <div class="dropdown">
        <button
          app-icon-button
          class="mx-1"
          color="muted"
          data-bs-toggle="dropdown"
          tabindex="0"
          title="Open support menu"
          data-userflow="support-button"
        >
          <i class="fa-light fa-headset"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end">
          <div (click)="openSupport()" class="dropdown-item d-flex dropdown-item-icon-align">
            <div>Support and FAQ</div>
            <div class="fa-light fa-up-right-from-square ps-4"></div>
          </div>
          <div (click)="openReportIssueOffCanvas()" class="dropdown-item">
            Report Issue
          </div>
          <div class="dropdown-divider"></div>
          <h6 class="dropdown-header">TOURS</h6>
          @if (AppContext.useUserflowFeatures()) {
          <div (click)="openSecureChainTour()" class="dropdown-item d-flex dropdown-item-icon-align">
            <div>Secure chain</div>
            <div class="fa-light fa-shoe-prints ps-4"></div>
          </div>
          <div (click)="openEmailNotificationTour()" class="dropdown-item d-flex dropdown-item-icon-align">
            <div>Email notifications</div>
            <div class="fa-light fa-shoe-prints ps-4"></div>
          </div>
          }
        </div>
      </div>
    </div>

    <button app-icon-button class="mx-1 beamer-button hide-md-screen" color="muted" tabindex="0" title="Open Beamer">
      <i class="fal fa-bullhorn"></i>
    </button>

    <div class="dropdown">
      <button
        app-icon-button
        class="mx-1"
        color="muted"
        data-bs-toggle="dropdown"
        tabindex="0"
        title="Open user menu"
      >
        <i class="fa-light fa-user-gear"></i>
      </button>
      <div class="dropdown-menu dropdown-menu-end">
        <div class="dropdown-item show-sm-screen">
          <span class="beamer-button">News</span>
        </div>
        <a (click)="editNotificationSettings()" class="dropdown-item show-sm-screen">
          Notification settings
        </a>
        <a (click)="openSupport()" class="dropdown-item show-sm-screen">
          Support and FAQ
          <span class="fa-light fa-up-right-from-square ps-4"></span>
        </a>
        <a (click)="openReportIssueOffCanvas()" class="dropdown-item show-sm-screen">
          Report issue
        </a>
        <a (click)="openReferenceData()" class="dropdown-item hide-md-screen">
          Reference data
        </a>
        <a (click)="openDeveloperDocumentation()" class="dropdown-item hide-md-screen">
          Developer documentation
          <span class="fa-light fa-up-right-from-square ps-4"></span>
        </a>
        <div class="dropdown-divider show-sm-screen"></div>
        <div (click)="logOut()" class="dropdown-item" data-bs-toggle="modal">
          Log out
        </div>
      </div>
    </div>
  </div>
</nav>
