import {NgIf} from "@angular/common";
import {Component, ElementRef, OnInit, Type} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {ReportIssue} from "@portbase/hinterland-service-typescriptmodels";
import {Subject} from "rxjs";

import {AppContext} from '../../../app-context';
import {HinterlandBaseComponent} from '../../../common/hinterland-base-component';
import {SingleClickDirective} from "../../../common/single-click.directive";
import {checkValidity, sendCommand} from '../../../common/utils';
import {HinterlandUtils} from '../../../hinterland/hinterland-utils';
import {CloseEditingDialogComponent} from '../../dialogs/close-editing-dialog/close-editing-dialog.component';
import {ErrorComponent} from "../../errors/error/error.component";
import {EditModalService} from '../../modals/edit-modal/edit-modal.service';
import {FormFieldComponent} from "../../user-forms/form-field/form-field.component";
import {ClosableModal} from "../../modals/edit-modal/edit-modal.component";

@Component({
    selector: 'app-report-issue-offcanvas',
    templateUrl: './app-report-issue-offcanvas.html',
    styleUrls: ['./app-report-issue-offcanvas.scss'],
    standalone: true,
    imports: [ErrorComponent, FormFieldComponent, FormsModule, NgIf, SingleClickDirective]
})
export class ReportIssueOffCanvasComponent extends HinterlandBaseComponent implements OnInit, ClosableModal {
  data = {};

  isModalClosable = true;
  emailAddress: string;
  feedback: string;
  errors: string[];
  validationErrors: boolean;
  disableSubmit = new Subject<boolean>();

  ngOnInit() {
    this.errors = [];
    this.emailAddress = AppContext.userProfile.emailAddress;
  }

  constructor(
      private elementRef: ElementRef,
      private readonly editModalService: EditModalService,
  ) {
    super();
  }

  reportIssue() {
    if (checkValidity(this.elementRef)) {
      this.validationErrors = false;
      HinterlandUtils.makeScreenshot("#main-menu-screenshot-root").then((screenshot) => {
        sendCommand('com.portbase.common.api.reporting.ReportIssue', {
            '@class': 'io.fluxcapacitor.javaclient.common.Message',
            payload: <ReportIssue>{
              'modality': "road",
              'email': this.emailAddress,
              'contact': AppContext.userProfile.contact,
              'issue': this.feedback,
              'url': window.location.href,
            },
            metadata: {
              'upload': screenshot
            }
          },
          () => {
            AppContext.publishSuccessMessage("The issue has been reported. Thank you.");
            this.closeModal();
          },
          (error) => {
            this.errors.push(error?.error?.error);
            this.disableSubmit.next(false);
          }
        );
      });
    } else {
      this.validationErrors = true;
      this.disableSubmit.next(false);
    }
  }

  setModalClosable(closable: boolean) {
    this.isModalClosable = closable;
  }

  closeModal() {
    this.isModalClosable = true;
    this.editModalService.closeModal();
  }

  switchModal() {
    this.closeModal();
  }

  getCloseDialogComponentType(): Type<unknown> {
    return CloseEditingDialogComponent;
  }

}
