import { Component, HostBinding, Input } from '@angular/core';

export type ButtonColor = 'regular' | 'dangerous';
export type ButtonDesign = 'primary' | 'secondary';
export type ButtonIconPosition = 'icon-none' | 'icon-start' | 'icon-end';

@Component({
  selector: 'button[app-button]',
  standalone: true,
  template: '<ng-content></ng-content>',
  styleUrl: './button.component.scss'
})
export class ButtonComponent {

  @Input() color: ButtonColor = 'regular';
  @Input() design: ButtonDesign = 'primary';
  @Input() iconPosition: ButtonIconPosition = 'icon-none';

  @HostBinding() role = 'button';
  @HostBinding() type = 'button';

  @HostBinding('class')
  get buttonClass(): string[] {
    return [this.color, this.design, this.iconPosition];
  }
}
