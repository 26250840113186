import { NgIf } from "@angular/common";
import { Component, HostBinding, Input } from "@angular/core";
import { RouterLink, RouterLinkActive } from "@angular/router";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "li[app-main-navbar-left-item]",
  templateUrl: "./main-navbar-left-item.component.html",
  styleUrls: ["./main-navbar-left-item.component.scss"],
  standalone: true,
  imports: [RouterLink, RouterLinkActive, NgIf],
})
export class MainNavbarLeftItemComponent {
  @Input() href?: string;
  @Input() icon?: string;
  @Input() external = false;

  @HostBinding() class = "nav-item";
}
