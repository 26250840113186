import { NgOptimizedImage } from "@angular/common";
import { Component } from "@angular/core";

import { AppContext } from "../../../app-context";

@Component({
  selector: "app-main-banner",
  templateUrl: "./main-banner.component.html",
  styleUrls: ["./main-banner.component.scss"],
  imports: [NgOptimizedImage],
  standalone: true,
})
export class MainBannerComponent {
  startUrl = `https://start.${AppContext.environment}.portbase.com`;
}
