<div class="form-group row" [class]="customClass">
  <div class="col-12 col-form-label">
    <ng-container *ngIf="tooltip; else labelText">
      <span class="form-field-label">
          <ng-container *ngTemplateOutlet="labelText"></ng-container>
        </span>
      <span class="info-icon" [ngbTooltip]="infoTooltip" container="body" [placement]="tooltipPlacement">
        <i class="fa-light fa-circle-info"></i>
      </span>
    </ng-container>
  </div>
  <div class="col-12">
    <ng-content></ng-content>
  </div>
</div>

<ng-template #infoTooltip>
  <div class="tooltip-card">
    <div class="tooltip-card-body">
      {{tooltip}}
    </div>
  </div>
</ng-template>

<ng-template #labelText>
  <ng-container *ngIf="label; else fromTemplate"><label>{{label}}</label></ng-container>
  <span *ngIf="!required && renderOptional" class="form-field-optional text-secondary">(optional)</span>
</ng-template>

<ng-template #fromTemplate>
  <ng-content select="label"></ng-content>
  <ng-content select=".formGroupLabel"></ng-content>
</ng-template>
