import {Component, Input, OnInit} from '@angular/core';
import {DateRange} from "@portbase/hinterland-service-typescriptmodels";
import moment from "moment";
import {ClosableModal} from "../../../../components/modals/edit-modal/edit-modal.component";
import {AppContext} from "../../../../app-context";

@Component({
  selector: 'app-date-range-dialog',
  templateUrl: 'date-range-dialog.component.html',
  styleUrls: ['date-range-dialog.scss']
})
export class DateRangeDialogComponent implements OnInit {

  @Input() data: ClosableModal;

  callback: (dateRange: DateRange) => any;
  displayMonths: number = 2;
  dateRange: DateRange;
  selectedDateRange: DateRange;
  error: string = null;

  ngOnInit() {
    this.dateRange = this.data['dateRange'];
    this.displayMonths = this.data['displayMonths'];
  }

  onDateRangeChanged = (dateRange: DateRange): any => {
    this.selectedDateRange = dateRange;
  }

  apply() {
    if (this.selectedDateRange === undefined) {
      this.callback(this.selectedDateRange);
    } else {
      if (!AppContext.isAdmin() && moment(this.selectedDateRange.end).diff(moment(this.selectedDateRange.start), 'days') > 31) {
        this.error = "Selected dates should be a maximum of 31 days apart.";
      } else if (this.data['validateMonths'] && moment(this.selectedDateRange.start).isBefore(moment().subtract(13, 'months'))) {
        this.error = "Please select a period of a maximum of 31 days in the last 13 months.";
      } else {
        this.error = null;
        this.callback(this.selectedDateRange);
      }
    }
  }

}
