import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Type,
  ViewChild,
} from '@angular/core';
import Modal from 'bootstrap/js/dist/modal';
import { Subscription } from 'rxjs';

import { ConfirmationModalService } from '../confirmation-modal/confirmation-modal.service';
import { ModalDirective } from '../modal.directive';

import { EditModalService } from './edit-modal.service';

@Component({
  selector: 'app-edit-modal',
  templateUrl: './edit-modal.component.html',
  styleUrls: ['./edit-modal.component.scss'],
  standalone: true,
  imports: [
    ModalDirective
  ]
})
export class EditModalComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('modal', {static: true}) modal: ElementRef<HTMLDivElement>;
  @ViewChild(ModalDirective, {static: true}) modalHost: ModalDirective;
  @ViewChild('modalContainer', {static: true}) modalContainer: ElementRef<HTMLDivElement>;

  dirty: boolean;

  subscription?: Subscription;

  bootstrapModal?: Modal;

  constructor(
    private readonly editModalService: EditModalService,
    private readonly confirmationModalService: ConfirmationModalService,
  ) {}

  ngOnInit() {
    this.subscription = this.editModalService.modalData$.subscribe((event) => {
      if (event) {
        this.openEditModal(event);
      } else {
        this.closeEditModal();
      }
    });
  }

  ngAfterViewInit(): void {
    $(this.modal.nativeElement).on('hide.bs.modal', (e) => {
      if (this.dirty) {
        e.preventDefault();
        this.confirmationModalService.openModalWithCallback((result: boolean) => {
          if (result) {
            this.closeEditModal()
          }
        });
      }
    });
    $(this.modal.nativeElement).on('hidden.bs.modal', () => {
      this.modalHost.viewContainerRef.clear();
    })
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private openEditModal(event: EditModalEvent) {
    this.modalHost.viewContainerRef.clear();
    this.dirty = false;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const componentRef = this.modalHost.viewContainerRef.createComponent<any>(event.component);
    componentRef.instance.data = event.data;
    componentRef.location.nativeElement.classList.add('modal-content');
    this.bootstrapModal = new Modal(this.modal.nativeElement);
    this.bootstrapModal.show();
  }

  private closeEditModal() {
    this.dirty = false;
    this.bootstrapModal?.hide();
  }
}

export interface EditModalEvent {
  component: Type<unknown>;
  data?: object;
}

export declare interface ClosableModal {
  isModalClosable: boolean;

  setModalClosable(closable: boolean);

  closeModal();

  switchModal();

  getCloseDialogComponentType(): Type<unknown>;
}
