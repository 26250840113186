import {Component} from '@angular/core';

import {environment} from "../../../../environments/environment";
import {AppContext} from "../../../app-context";
import {
  LogOutConfirmationDialogComponent
} from "../../dialogs/logout-confirmation-dialog/log-out-confirmation-dialog.component";
import {ConfirmationModalService} from "../../modals/confirmation-modal/confirmation-modal.service";
import {EditModalService} from "../../modals/edit-modal/edit-modal.service";
import {
  NotificationSettingsOffCanvasComponent
} from "../../tools/notification-settings-offcanvas/app-notification-settings-offcanvas.component";
import {
  ReferenceDataOffCanvasComponent
} from "../../tools/reference-data-offcanvas/app-reference-data-offcanvas.component";
import {ReportIssueOffCanvasComponent} from "../../tools/report-issue-offcanvas/app-report-issue-offcanvas.component";
import {IconButtonComponent} from "../../icon-button/icon-button.component";
import userflow from "userflow.js";

@Component({
  selector: "app-main-navbar-top",
  templateUrl: "./main-navbar-top.component.html",
  styleUrls: ["./main-navbar-top.component.scss"],
  imports: [IconButtonComponent],
  standalone: true,
})
export class MainNavbarTopComponent {

  constructor(
    private readonly editModalService: EditModalService,
    private readonly confirmationModalService: ConfirmationModalService,
  ) {
  }

  openSupport() {
    const language = AppContext.getPreferredLanguage();
    const path = language.match("nl")
      ? "services/melding-container-achterland-road/"
      : "en/services/hinterland-container-notification-road/";
    window.open(`https://support.portbase.com/${path}`, "_blank").focus();
  }

  openDeveloperDocumentation() {
    window
      .open("https://developer.portbase.com/services#hinterland", "_blank")
      .focus();
  }

  openReferenceData() {
    this.editModalService.openModal(ReferenceDataOffCanvasComponent);
  }

  editNotificationSettings() {
    this.editModalService.openModal(NotificationSettingsOffCanvasComponent);
  }

  openReportIssueOffCanvas() {
    this.editModalService.openModal(ReportIssueOffCanvasComponent);
  }

  logOut() {
    this.confirmationModalService.openModalWithCallback(
      (result) => {
        if (result) {
          if (environment.production || environment.docker) {
            window.location.href = "/api/logout";
          }
        }
      },
      LogOutConfirmationDialogComponent,
      this,
    );
  }

  // noinspection
  openSecureChainTour() {
    userflow.start('5846e42e-ec42-432a-9093-786e00c79a45');
  }

  // noinspection
  openEmailNotificationTour() {
    userflow.start('1088033d-b9cd-44fd-a174-1bb27a953044');
  }

  protected readonly AppContext = AppContext;
}
