import {NgIf} from '@angular/common';
import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';

import {EditModalService} from '../modals/edit-modal/edit-modal.service';
import {IconButtonComponent} from "../icon-button/icon-button.component";

@Component({
  selector: 'app-offcanvas',
  templateUrl: './offcanvas.component.html',
  styleUrls: ['./offcanvas.component.css'],
  imports: [
    IconButtonComponent,
    NgbTooltip,
    NgIf,
  ],
  standalone: true
})
export class OffcanvasComponent implements OnInit {

  @Input() isModalClosable = true;

  @Input() panelTitle = 'Offcanvas Title';
  @Input() errorText?: string;
  @Input() infoText?: string;
  @Input() infoTooltip?: string;
  @Input() showFooter = true;

  data = true
  validationErrors = []

  constructor(
    private element: ElementRef,
    private readonly editModalService: EditModalService,
  ) {
  }

  ngOnInit(): void {
  }

  setModalClosable(closable: boolean) {
    this.isModalClosable = closable;
  }

  closeModal() {
    this.isModalClosable = true;
    this.editModalService.closeModal();
  }

}
