import {Component, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {NgbCalendar, NgbDate, NgbDatepicker, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {DateRange} from "@portbase/hinterland-service-typescriptmodels/hinterland";
import moment from "moment";
import {Observable} from "rxjs";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {AbstractValueAccessorComponent} from "../component/value-accessor.component";

@Component({
    selector: 'app-date-picker-range',
    templateUrl: 'date-picker-range.html',
    styleUrls: ['date-picker-range.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DatepickerRangeComponent), multi: true },
    ],
    standalone: true,
    imports: [NgbDatepicker]
})
export class DatepickerRangeComponent extends AbstractValueAccessorComponent<DateRange> implements OnInit {

  @Input() initDateRange: DateRange;
  @Input() onDateRangeChanged: (arg: DateRange) => Observable<any[]> | any[];
  @Input() displayMonths: number = 2;

  calendar: NgbCalendar;
  start: NgbDateStruct;
  end: NgbDateStruct;
  hoveredDate: NgbDateStruct;

  @ViewChild('picker') datepicker: NgbDatepicker;

  constructor(calendar: NgbCalendar) {
    super();
    this.calendar = calendar;
  }

  ngOnInit() {
    this.start = this.convertToDate(this.initDateRange?.start);
    this.end = this.convertToDate(this.initDateRange?.end);
  }

  ngAfterViewInit() {
    this.datepicker.navigateTo(this.start);
  }

  get value(): DateRange {
    return {
      start: this.convertToString(this.start),
      end: this.convertToString(this.end ? this.end : this.start)
    };
  }

  writeValue(value: DateRange) {
    this.onDateRangeChanged(this.value);
  }

  onDateSelection(date: NgbDate) {
    if (!this.start && !this.end) {
      this.start = date;
    } else if (this.start && !this.end && date.after(this.start)) {
      this.end = date;
    } else {
      this.start = date;
      this.end = null;
    }

    this.onDateRangeChanged(this.value);
  }

  isHovered(date: NgbDate) {
    return (
      this.start && !this.end && this.hoveredDate && date.after(this.start) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.end && date.after(this.start) && date.before(this.end);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.start) ||
      (this.end && date.equals(this.end)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  isStart(date: NgbDate) {
    return date.equals(this.start);
  }

  isEnd(date: NgbDate) {
    return date.equals(this.end);
  }

  isToday(date: NgbDate) {
    return !this.isRange(date) && !this.isStart(date) && !this.isEnd(date) && date.equals(this.calendar.getToday());
  }

  isSingle(date: NgbDate) {
    return this.start && this.end === null && date.equals(this.start);
  }

  convertToString(date: NgbDateStruct): string {
    if (!date) {
      return null;
    }
    const m = moment({y: date.year, M: date.month - 1, d: date.day});
    return m.isValid() ? m.format("YYYY-MM-DD") : null;
  }

  convertToDate(date: string): NgbDateStruct {
    return date ? {year: moment(date).year(), month: moment(date).month() + 1, day: moment(date).date()} : undefined;
  }

}
