import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ButtonComponent} from '../button/button.component';
import {IconButtonComponent} from '../icon-button/icon-button.component';

@Component({
  selector: 'app-dialog',
  standalone: true,
  imports: [ButtonComponent, IconButtonComponent],
  templateUrl: './dialog.component.html',
  styleUrl: './dialog.component.scss',
})
export class DialogComponent {
  @Input() color: 'regular' | 'dangerous' = 'regular';

  @Input({required: true}) dialogTitle!: string;
  @Input({required: true}) dialogMessage!: string;

  @Input({required: true}) primaryActionLabel!: string;
  @Output() primaryAction = new EventEmitter<void>();

  @Input() secondaryActionLabel?: string;
  @Output() secondaryAction = new EventEmitter<void>();

  /**
   * Output
   */
  @Output() dismissAction = new EventEmitter<void>();
}
