import {Component, inject, Input} from '@angular/core';

import {ConfirmationModalService} from '../../modals/confirmation-modal/confirmation-modal.service';
import {DialogComponent} from "../../dialog/dialog.component";
import {ClosableModal} from "../../modals/edit-modal/edit-modal.component";

@Component({
  selector: 'app-close-editing-dialog',
  templateUrl: './close-editing-dialog.component.html',
  styleUrls: ['./close-editing-dialog.component.scss'],
  imports: [
    DialogComponent
  ],
  standalone: true
})
export class CloseEditingDialogComponent {

  @Input() data: ClosableModal;

  readonly #confirmationModalService = inject(ConfirmationModalService);

  closeWithoutSaving() {
    this.#confirmationModalService.closeModal();
    this.data.closeModal();
  }

  dismiss() {
    this.#confirmationModalService.closeModal();
  }
}
