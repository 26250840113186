<div
  class="col d-flex h-100 justify-content-center align-items-center banner"
>
  <a class="full-logo d-none d-xl-block" [href]="startUrl">
    <img
      alt="Portbase Portal"
      height="32"
      ngSrc="assets/logo-portbase.svg"
      width="128"
      priority
    />
  </a>

  <a class="collapsed-logo d-block d-xl-none" [href]="startUrl">
    <img
      alt="Portbase Portal"
      height="32"
      ngSrc="assets/logo-portbase-collapsed.svg"
      width="32"
      priority
    />
  </a>
</div>
