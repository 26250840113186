import _ from 'lodash';

export const ASCENDING = "+";
export const DESCENDING = "-";
export const EQUAL = 0;
export const BEFORE = -1;
export const AFTER = 1;

export class ComparatorChain {
  private properties;

  constructor(...properties) {
    this.properties = Array.from(properties)
      .map(property => ComparatorChain.parseProperty(property));
  }

  addProperty = (...properties) => {
    properties.forEach(property => this.properties.push(ComparatorChain.parseProperty(property)))
    return this;
  }

  static parseProperty = property => {
    if (_.isFunction(property)) {
      return property;
    }

    const direction = property.charAt(0);
    const hasDirection = direction === ASCENDING || direction === DESCENDING;
    return {
      direction: hasDirection ? direction : ASCENDING,
      propertyName: hasDirection ? property.slice(1) : property
    };
  };

  compare = (a, b) => {
    let result = EQUAL;

    for (const property of this.properties) {
      if (_.isFunction(property)) {
        result = property.call(this, a, b);
      } else {
        result = ComparatorChain.compareProperty(a, b, property);
      }

      if (result !== EQUAL) {
        break;
      }
    }
    return result;
  };

  static compareProperty = (a, b, {propertyName, direction}) => {
    var propertyA = getPath(a, propertyName);
    var propertyB = getPath(b, propertyName);

    if (typeof propertyA == "string") {
      propertyA = propertyA.toUpperCase();
    }

    if (typeof propertyB == "string") {
      propertyB = propertyB.toUpperCase();
    }

    if (propertyA === propertyB) {
      return EQUAL;
    }

    if (propertyA === null || propertyA === undefined) {
      return AFTER;
    }

    if (propertyB === null || propertyB === undefined) {
      return BEFORE;
    }

    if (direction === ASCENDING) {
      return propertyA > propertyB ? AFTER : BEFORE;
    }

    return propertyA < propertyB ? AFTER : BEFORE;
  };
}

function getPath(object, path) {
  if (object === null || typeof object === 'undefined' || typeof path !== 'string') {
    return undefined;
  }

  var index = path.trim().indexOf('.');
  if (index < 0) {
    return object[path];
  }

  var key = path.substr(0, index);
  var next = path.substr(index + 1);
  return getPath(object[key], next);
}
