import {Component} from '@angular/core';
import {DialogComponent} from "../../dialog/dialog.component";

@Component({
  selector: 'app-default-confirmation-dialog',
  templateUrl: './default-confirmation-dialog.component.html',
  styleUrls: ['./default-confirmation-dialog.component.scss'],
  standalone: true,
  imports: [
    DialogComponent
  ]
})
export class DefaultConfirmationDialogComponent {

  callback: (result: boolean) => void;

  continueEditing = () => {
    this.callback(false);
  };

  close = () => {
    this.callback(true);
  };
}
