import {cloneObject, convertCamelCase, sendQuery, toTitleCase} from '../common/utils';
import {
  AttachedHandling,
  Barge,
  BargeVoyage,
  BargeVoyageData,
  BinnenhavengeldLocation,
  BinnenhavengeldOrganisation,
  BinnenhavengeldShip,
  CargoPackageType,
  ContainerType,
  DateTimeRange,
  DetachedHandling,
  FindBarges,
  FindBinnenhavengeldShips,
  FindTerminalVisits,
  FindVisitResult,
  FindVisits,
  FindVoyagesForMovingVisit,
  GetContainer,
  GetNextTripNumber,
  GoodsClassification,
  Handling,
  LoadDischargeListStatus,
  Modality,
  PackingGroup,
  RailVoyageData,
  RoadVoyage,
  RoadVoyageData,
  ShippingCompany,
  Terminal,
  Visit,
  VisitData,
  VisitStatus,
  Voyage,
  VoyageData,
  VoyageStatus
} from '@portbase/hinterland-service-typescriptmodels/hinterland';
import {filter, map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {AppContext} from '../app-context';
import moment, {now} from 'moment';
import html2canvas from 'html2canvas';
import {ComparatorChain} from '../common/comparator-chain';
import {
  Charter,
  DayOfWeek,
  DeepSea,
  DeepSeaReference,
  GetOrganisation,
  HinterlandOrganisation,
  OrganisationPreferences,
  RailVoyage,
  TerminalSettings
} from "@portbase/hinterland-service-typescriptmodels";
import {downloadCsv} from "../common/download/download.utils";

export class HinterlandUtils {
  //consignment refdata
  static containerTypeOptions = <ContainerType[]>['CHASSIS', 'CONTAINER', 'SWAP_BODY', 'TRAILER'];
  static packingGroups: PackingGroup[] = ["MINOR_DANGER", "MEDIUM_DANGER", "GREAT_DANGER"];

  static enumFormatter: (value: string) => string = v => {
    return toTitleCase(v).replace(/_/g, " ");
  };

  static noOpSearchFunction = () => of();

  static containerOperatorFormatter = (carrier: ShippingCompany) => carrier && carrier.name ? (carrier.scacCode ? carrier.scacCode + ' - ' : '') + carrier.name : '';

  static goodsClassificationFormatter = (value: GoodsClassification) => value ? value.code + ' – ' + value.description : '';

  static packageTypeFormatter = (value: CargoPackageType) => value ? value.code + ' – ' + toTitleCase(value.name) : '';

  static camelCaseFormatter = (value: string) => {
    return convertCamelCase(value);
  };

  static terminalFormatter = (terminal: TerminalModel) => {
    return terminal.displayName;
  }

  static bookingNumberFormatter = (value: string) => {
    if (!!value) {
      return value
        .toUpperCase()
        .replace('\'', '')
        .replace('`', '')
        .replace(':', '');
    }

    return value;
  }

  static packingGroupFormatter = (value: PackingGroup) => {
    switch (value) {
      case "GREAT_DANGER":
        return 'I (great danger)';
      case "MEDIUM_DANGER":
        return 'II (medium danger)';
      case "MINOR_DANGER":
        return 'III (minor danger)';
    }
  };

  static handlingStatuses: HandlingStatus[] = ['stuck', 'declared', 'inOperation', 'handled', 'rejected', 'acceptedWithWarnings', 'accepted', 'unknown', 'cancelled'];
  static weekdays = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

  static getAllTerminalOrganisations = (includeNonPcs: boolean, includeOther: boolean) =>
    sendQuery("com.portbase.hinterland.api.common.query.GetTerminals", {})
      .pipe(map((r: TerminalModel[]) => r
        .filter(t => {
          return (includeNonPcs || !t.nonPcs) && !(t.shortName === 'NONPCS_OTHER' && !includeOther)
        })
        .filter((terminal, index, terminals) => {
          const foundIndex = terminals.findIndex((item) => item.shortName === terminal.shortName);
          return index === foundIndex;
        })
        .map(t => {
          const payload: GetOrganisation = <GetOrganisation>{
            term: t.shortName
          };
          sendQuery("com.portbase.hinterland.api.common.query.GetOrganisation", payload)
            .subscribe(o => {
              return t.displayName = !!o && !!o.fullName ? o.fullName : t.shortName;
            });
          return t;
        })
      ));

  static getTerminals = (modality: Modality, includeNonPcs: boolean, includeOther: boolean, onlyRequestableByDeclarant: boolean) => modality
    ? sendQuery("com.portbase.hinterland.api.common.query.GetTerminals", {})
      .pipe(map((r: TerminalModel[]) => r.filter(t => {
          if (onlyRequestableByDeclarant) {
            return t.allowedModalities.indexOf(modality) >= 0 && (includeNonPcs || !t.nonPcs) && !(t.shortName === 'NONPCS_OTHER' && !includeOther) && !t.quaySelectByDeclarantForbidden
          } else {
            return t.allowedModalities.indexOf(modality) >= 0 && (includeNonPcs || !t.nonPcs) && !(t.shortName === 'NONPCS_OTHER' && !includeOther)
          }
        }).map(t => {
          t.displayName = HinterlandUtils.getTerminalDisplayName(t, modality);
          return t;
        }).filter(t => !t.prenotificationRestrictedToDeclarants
          || (t.prenotificationRestrictedToDeclarants && !t.prenotificationRestrictedToDeclarants[modality])
          || (t.prenotificationRestrictedToDeclarants && t.prenotificationRestrictedToDeclarants[modality]
            && t.prenotificationRestrictedToDeclarants[modality].includes(AppContext.userProfile.organisationShortName)))
      ))
    : of([]);

  static getTerminalDisplayName(terminal: Terminal, modality: string) {
    if (terminal?.displayNameOverrides && modality) {
      return terminal?.displayNameOverrides[modality] ? terminal.displayNameOverrides[modality] : terminal.quayName;
    }
    return terminal?.quayName;
  }

  static getSlicedTerminalDisplayName(terminal: Terminal, modality: string) {
    return this.getSlicedDisplayName(this.getTerminalDisplayName(terminal, modality));
  }

  static getSlicedDisplayName(displayName: string) {
    if (!!displayName) {
      return (displayName.length > 12 ? displayName.slice(0, 12) + '..' : displayName).toUpperCase();
    } else {
      return displayName;
    }
  }

  static isLossenOpRichtlijnAllowed(terminal: Terminal, modality: string) {
    if (!!terminal) {
      return terminal.lossenOpRichtlijnAllowed
        || terminal.terminalSettings.some(s => s.modality === modality && !s.full && !s.loading && s.lossenOpRichtlijnAllowed);
    }
    return false;
  }

  static getRailInterTerminals = () => sendQuery("com.portbase.hinterland.api.common.query.GetRailInterTerminals", {});
  static getBargeAccessPoints = sendQuery("com.portbase.hinterland.api.barge.query.GetBargeAccessPoints", {portUnCode: 'NLRTM'});
  static findPreviousVoyages = (term, voyageData) => sendQuery("com.portbase.hinterland.api.common.query.FindPreviousVoyages", {
    europeId: voyageData.barge.europeId,
    inhouseNumber: term,
    dateTimeRange: HinterlandUtils.previousVoyageDateTimeRange(voyageData.eta)
  }, {caching: false}).pipe(map(voyages => voyages.filter(v => !v.cancelled)))
    .pipe(map(voyages => voyages.sort(voyageComparator)));
  static findCharters = term => sendQuery("com.portbase.hinterland.api.road.query.FindCharters", {term: term});
  static charterFormatter = (v: Charter) => v && v.name ? (toTitleCase(v.name) + ' – ' + v.ean) : '';
  static findSizeTypes = term => sendQuery("com.portbase.hinterland.api.common.query.FindSizeTypes", {term: term});
  static findShippingCompanies = term => sendQuery("com.portbase.hinterland.api.refdata.query.FindShippingCompanies", {term: term})
    .pipe(map((r: ShippingCompany[]) => r.filter(c => !!c.smdgCode)));
  static getAllShippingCompanies = () => sendQuery("com.portbase.hinterland.api.refdata.query.GetShippingCompanies", {});
  static findTractionSuppliers = term => sendQuery("com.portbase.hinterland.api.rail.query.FindTractionSuppliers", {term: term});
  static findBarges = term => sendQuery("com.portbase.hinterland.api.barge.query.FindBarges", <FindBarges>{term: term});
  static findBinnenhavengeldShips = term => sendQuery("com.portbase.hinterland.api.binnenhavengeld.FindBinnenhavengeldShips", <FindBinnenhavengeldShips>{term: term});
  static getBinnenhavengeldLocations = () => sendQuery("com.portbase.hinterland.api.binnenhavengeld.GetBinnenhavengeldLocations", {});
  static findForwarders = term => sendQuery("com.portbase.hinterland.api.barge.query.FindForwarders", {term: term});
  static findOrganisations = term => sendQuery("com.portbase.hinterland.api.common.query.FindOrganisations", {term: term});
  static findInlandOperators = term => sendQuery("com.portbase.hinterland.api.common.query.FindInlandOperators", {term: term});
  static hinterlandOrganisationFormatter = (result: HinterlandOrganisation) => result ? result.fullName + ' (' + result.ean + ')' : '';
  static findLocations = term => sendQuery("com.portbase.hinterland.api.refdata.query.FindLocations", {term: term});
  static getContainer = equipmentNumber => !equipmentNumber || equipmentNumber == "" ? of([]) :
    sendQuery("com.portbase.hinterland.api.refdata.query.GetContainer", <GetContainer>{equipmentNumber: equipmentNumber});

  static findVisits = (term: string, modality?: Modality) =>
    sendQuery("com.portbase.hinterland.api.common.query.FindVisits", <FindVisits>{
      term: term,
      modality: modality
    }, {caching: false});

  static visitResultFormatter = (result: FindVisitResult) => {
    if (!result) {
      return "";
    }
    if (result.tar) {
      return `TAR: ${result.tar}`
    }
    if (result.modality === "rail") {
      const railVoyageData = <RailVoyageData>result.voyageData;
      const trackNumber = railVoyageData.dischargeVoyageNumber ? railVoyageData.dischargeVoyageNumber : railVoyageData.loadingVoyageNumber;
      return `${result.visitId} ${railVoyageData.train.trainNumber} ${trackNumber} ${result.terminal.shortName}`;
    }
    return result.visitId;
  }

  static findVisitPlaceholder = (modality: Modality) => {
    if (modality === "road") {
      return "Find by TAR or call ID"
    }
    if (modality === 'rail') {
      return "Find by trainnr, tracknr or call ID"
    }
    return "Find by call ID"
  }

  static findVoyagesForMovingVisit = term => sendQuery("com.portbase.hinterland.api.common.query.FindVoyagesForMovingVisit", <FindVoyagesForMovingVisit>{term: term}, {caching: false});

  static getNextVisitId = (): Observable<string> =>
    sendQuery('com.portbase.hinterland.api.common.query.GetNextVisitId', {},
      {caching: false, showSpinner: true});

  static getNextTripNumber = (): Observable<string> =>
    sendQuery('com.portbase.hinterland.api.common.query.GetNextTripNumber', <GetNextTripNumber>{},
      {caching: false, showSpinner: true}).pipe(map(number => {
      return number['tripNumber']
    }));

  static findTerminalVisits = terminal => term => sendQuery("com.portbase.hinterland.api.common.query.FindTerminalVisits", <FindTerminalVisits>{
    term: term,
    terminalShortname: terminal?.shortName
  }, {caching: false}).pipe(map((results: DeepSea[]) => {
    return results.map(deepSea => {
      deepSea['terminal'] = HinterlandUtils.getTerminalDisplayName(terminal, 'road');
      return deepSea;
    });
  }));

  static findTerminalVisitReferences = terminal => term => sendQuery("com.portbase.hinterland.api.common.query.FindTerminalVisits", <FindTerminalVisits>{
    term: term,
    terminalShortname: terminal?.shortName
  }, {caching: false}).pipe(map((results: DeepSea[]) => {
    return results.map(deepSea => {
      return <DeepSeaReference>{
        imoCode: deepSea.imoCode,
        shipName: deepSea.shipName,
        crnNumber: deepSea.crnNumber,
        eta: deepSea.eta,
        etd: deepSea.etd,
        terminal: HinterlandUtils.getTerminalDisplayName(terminal, 'road')
      };
    });
  }));

  static terminalVisitFormatter = (deepSea: any) => {
    if (!deepSea || !deepSea.shipName || !deepSea.imoCode) {
      return null;
    }

    let result = `${toTitleCase(deepSea.shipName)} (${deepSea.imoCode})`;
    if (deepSea.eta || deepSea.etd) {
      result += `  ${format(deepSea.eta)} - ${format(deepSea.etd)}`;
    }
    return result;

    function format(date: string) {
      if (!date) return '?';

      const now = moment();
      const m = moment(date);

      return m.format(m.year() === now.year() ? 'DD MMM HH:mm' : 'DD MMM [`]YY HH:mm');
    }
  };

  static seaVesselFormatter = (deepSea: DeepSea) => {
    if (!deepSea || !deepSea.shipName || !deepSea.imoCode) {
      return null;
    }

    let result = `${toTitleCase(deepSea.shipName)} (${deepSea.imoCode})`;
    if (deepSea.eta) {
      result = `${result}  ${formatText(deepSea.eta)} at ${formatDate(deepSea.eta)}`;
    }

    if (deepSea['terminal']) {
      result = `${result}  at ${deepSea['terminal']}`;
    }

    return result;

    function formatDate(date: string) {
      return moment(date).format('DD-MM-YYYY HH:mm');
    }

    function formatText(date: string) {
      return moment(date).isBefore(now()) ? 'arrived' : 'expected';
    }
  };

  static toTitleCase = v => toTitleCase(v);
  static bargeFormatter = (barge: Barge) => barge && barge.name ? (toTitleCase(barge.name) + ' – ' + barge.europeId) : '';
  static binnenhavengeldShipFormatter = (barge: BinnenhavengeldShip) => barge && barge.name ? barge.name + ' – ' + barge.eniNumber : '';
  static binnenhavengeldOrganisationFormatter = (o: BinnenhavengeldOrganisation) => o?.organisationName || '';
  static binnenhavengeldLocationFormatter = (location: BinnenhavengeldLocation) => toTitleCase(location && location.name);
  static previousVoyageBargeFormatter = (bargeVoyage: BargeVoyage) => {
    const voyageData = bargeVoyage.voyageData;
    return voyageData ? `${moment(voyageData.eta).format('DD-MM-YYYY')} - ${voyageData.inHouseDischargeNumber} - ${voyageData.inHouseLoadingNumber}` : '??';
  };
  static voyageBargeFormatter = (bargeVoyage: BargeVoyage) => {
    const voyageData = bargeVoyage.voyageData;
    return voyageData ? `${voyageData.barge.name} ${emptyOrValue(voyageData.barge.europeId, '(', ')')} ${emptyOrValue(voyageData.inHouseDischargeNumber, '- ')} ${emptyOrValue(voyageData.inHouseLoadingNumber, '- ')}` : '??';
  };
  static shippingCompanyFormatter = (carrier: ShippingCompany) => carrier && (carrier.scacCode ? carrier.scacCode : carrier.smdgCode) + (carrier.name ? ' - ' + carrier.name : '');
  static sizeTypeFormatter = (value) => value ? value.code + " – " + toTitleCase(value.name) : '';
  static locationFormatter = (value) => value ? value.locationUnCode + " – " + toTitleCase(value.name) : '';
  static ittFormatter = (value) => value ? value.name ? value.name : value.shortName : '';
  static temperatureChoiceFormatter = fixed => fixed ? "Fixed temperature" : "Temperature range";
  static containerStatusFormatter = value => value ? "Full" : "Empty";
  static handlingTypeFormatter = value => value === 'loading' ? "Pick up" : "Drop-off";
  static yesNoFormatter = value => value ? "Yes" : "No"
  static fixedWindowFormatter = value => value ? value.code + " (" + toTitleCase(value.window.start.day) + ' ' + value.window.start.time + ' - ' + (value.window.end.day != value.window.start.day ? toTitleCase(value.window.end.day) + ' ' : '') + value.window.end.time + ")" : ''
  static hasEtaInRange = (voyage: Voyage, start, end): boolean => {
    let eta;
    if (voyage.voyageData['eta']) {
      eta = moment(voyage.voyageData['eta']);
    } else {
      const firstVisit = voyage.visits.find(v => v.visitData.eta);
      eta = firstVisit && moment(firstVisit.visitData.eta);
    }
    return eta && eta.isBetween(start, end, "days", "[]");
  };

  static addShortName = (charter: Charter) => {
    if (charter && !charter.portbaseId) {
      sendQuery("com.portbase.hinterland.api.common.query.GetOrganisationByEan",
        {ean: charter.ean}).pipe(filter(r => !!r))
        .subscribe(o => charter.portbaseId = o.shortName);
    }
  };

  static getHandlingCount = (voyage: Voyage): number => {
    if (voyage['handlingSummary']) {
      return voyage['handlingSummary'].handlingCount || 0;
    }

    return voyage.visits.reduce((sum, v) => sum + v.handlings.length, 0);
  };

  static previousVoyageDateTimeRange(eta: string): DateTimeRange {
    return <DateTimeRange>{
      start: (eta != null ? moment(eta) : moment()).subtract(7, 'days').toISOString(),
      end: (eta != null ? moment(eta) : moment()).toISOString()
    };
  }

  static isVoyageDeclarantByShortName(voyageDeclarantShortname: string) {
    if (AppContext.isAdmin()) {
      return true;
    }
    return AppContext.userProfile.organisationShortName === voyageDeclarantShortname;
  }

  static isVoyageDeclarant(voyage: Voyage): boolean {
    if (AppContext.isAdmin()) {
      return true;
    }
    return voyage && AppContext.userProfile.organisationShortName === voyage.declarant.shortName;
  }

  static isCargoDeclarant(handling: any): boolean {
    if (AppContext.isAdmin()) {
      return true;
    }

    return AppContext.userProfile.organisationShortName === handling?.cargoDeclarantShortName
      || (!!handling?.declarant && AppContext.userProfile.organisationShortName === handling?.declarant.shortName);
  }

  static determineStatus(handling: Handling, visit: Visit = undefined): HandlingStatus {
    if (handling === undefined) {
      return "unknown"
    } else if (handling['attached'] === true || (handling['handlingResult'] !== undefined)) {
      return "handled";
    } else if (handling.cancelled) {
      return "cancelled";
    } else if (handling.declarationStatus === "REJECTED") {
      return "rejected";
    }

    if (visit !== undefined) {
      if (visit.arrived) {
        return "inOperation";
      } else if (visit.terminal.shortName.startsWith("NONPCS")) {
        return "unknown";
      }
    }

    if (handling.declarationStatus === "ACCEPTED") {
      return handling.handlingDeclaration.acceptStatuses.every(status => !!status.ok) ? "accepted" : "acceptedWithWarnings";
    } else if (handling.declarationStatus === "DECLARED") {
      return moment(handling.handlingDeclaration.timestamp).isBefore(moment().subtract(1, 'hours')) ? "stuck" : "declared";
    }

    return "unknown";
  }

  static isCharter(handling: HandlingModel): boolean {
    return ('charter' in handling.voyageData) && AppContext.userProfile.organisationShortName == handling.voyageData.charter.portbaseId
  }

  static isCharterNew(charter: Charter): boolean {
    return !!charter && AppContext.userProfile.organisationShortName == charter.portbaseId
  }

  static getHandlingStatus(handling: HandlingModel): HandlingStatus {
    if (!!handling.handlingResult) {
      return 'handled';
    }
    if (handling.cancelled) {
      return 'cancelled';
    }
    if (handling.declarationStatus === 'REJECTED') {
      return 'rejected';
    }
    if (handling.visitArrived) {
      return 'inOperation';
    }
    if (handling.declarationStatus === 'ACCEPTED') {
      return handling.handlingDeclaration.acceptStatuses.every(s => !!s.ok) ? 'accepted' : 'acceptedWithWarnings';
    }
    if (handling.declarationStatus === 'DECLARED') {
      if (handling.terminal.shortName.startsWith("NONPCS")) {
        return 'unknown';
      }
      return moment(handling.handlingDeclaration.timestamp).isBefore(moment().subtract(1, 'hours')) ? 'stuck' : 'declared';
    }
  }

  static nextMoment = (firstDepartureDate, dayAndTime): string => {
    const days: DayOfWeek[] = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"];
    if (!firstDepartureDate) return null;
    let firstDepartureMoment = moment(firstDepartureDate + ' ' + dayAndTime.time);
    let momentToFind = firstDepartureMoment.day(days.indexOf(dayAndTime.day));
    if (momentToFind.isBefore(moment(firstDepartureDate + ' ' + dayAndTime.time))) {
      momentToFind = momentToFind.add(1, 'week');
    }
    return momentToFind.format("YYYY-MM-DDTHH:mm:ssZ");
  }

  static getWorstVisitHandlingStatus(visit: Visit): HandlingStatus {
    const statuses = visit.handlings.map(h => (<HandlingModel>h).status).filter(s => !!s);
    statuses.sort((s1, s2) => HinterlandUtils.handlingStatuses.indexOf(s1) - HinterlandUtils.handlingStatuses.indexOf(s2));
    return statuses.length > 0 ? statuses[0] : null;
  }

  static isHandlingUpdateForbidden = (handling: HandlingModel, handlingOnInit: HandlingModel) => {
    return handling.preNotification
      && (handling.terminal.handlingUpdateForbidden && handling.terminal.handlingUpdateForbidden.indexOf(handling.modality) >= 0 || (handling.modality === 'barge' && !handling.terminal.updateAllowed))
      && !HinterlandUtils.isHandlingUpdateByPassAllowed(handling)
      && !handling.terminal.loadDischargeListSettings?.handlingUpdateBypassAllowed
      && handlingOnInit.preNotification
      && handlingOnInit.handlingDeclarations.some(d => d.status === 'DECLARED' || d.status === 'ACCEPTED')
      && (handling.visitLoadDischargeListStatus != "open" && handling.visitWaitForCloseVisitEnabled && !handling['waitingForCloseVisit']);
  }

  static isHandlingUpdateByPassAllowed = (handling: HandlingModel) => {
    return handling.terminal.handlingUpdateBypassAllowed && handling.terminal.handlingUpdateBypassAllowed.indexOf(handling.modality) >= 0;
  }

  static removeWhatDeclarantShouldNotSee(voyage: VoyageUnion, declarantShortName: string) {
    if (voyage.cargoDeclarants.filter(c => c.shortName === declarantShortName).length === 0) {
      return null;
    }

    if (voyage.declarant.shortName !== declarantShortName) {
      voyage = cloneObject(voyage);
      voyage.visits = voyage.visits.map(v => {
        v.handlings = v.handlings.filter(h => h.cargoDeclarantShortName === declarantShortName);
        return v;
      });
    }
    return voyage;
  }

  static trackByHandlingId(index: number, handling: HandlingModel) {
    return handling.new ? handling : handling.handlingId;
  }

  static findTerminalSettings(handling: HandlingModel): TerminalSettings {
    return handling.terminal.terminalSettings.find(
      c => c.full === !!handling.handlingData.full
        && c.loading === (handling.handlingData.type === 'loading')
        && c.modality === handling.handlingData.modality) || <TerminalSettings>{};
  }

  static downloadScacs() {
    const data = [["Name", "SCAC code", "SMDG code"]];
    HinterlandUtils.getAllShippingCompanies().subscribe(companies => {
      companies.forEach(company => data.push([
        company.name,
        company.scacCode,
        company.smdgCode
      ]));
      downloadCsv(data, 'hinterland_shippingcompanies.csv');
    });
  }

  static makeScreenshot(querySelector?): Promise<String> {
    AppContext.pendingProcesses.push("MakingScreenshot");
    return html2canvas((querySelector && document.querySelector(querySelector)) || document.body).then((canvas) => {
      let element = document.body.appendChild(canvas);
      let imageUrl = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
      document.body.removeChild(element);
      AppContext.pendingProcesses.splice(AppContext.pendingProcesses.indexOf('MakingScreenshot'), 1);
      return imageUrl;
    });
  }

  static buildKibanaUrl(query: String) {
    const from = moment().utc().subtract(2, "days").toISOString();
    const to = moment().utc().toISOString();

    if (!AppContext.environment) {
      //When running locally the URL is slightly different since /_plugin/kibana/ is not needed.
      return `http://localhost:5602/app/discover#/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:'${from}',to:'${to}'))&_a=(columns:!(_source),filters:!(),interval:auto,query:(language:kuery,query:'%22${query}%22'),sort:!())`
    }

    return `https://kibana.hcn.${AppContext.environment}.portbase.com/_dashboards/app/data-explorer/discover#?_a=(discover:(columns:!(_source),isDirty:!t,sort:!(!('@timestamp',desc))),metadata:(indexPattern:b04a9a20-752d-11ec-82ce-a1bf983aa12b,view:discover))&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:'${from}',to:'${to}'))&_q=(filters:!(),query:(language:lucene,query:%22${query}%22))`
  }

  static formatBytes(bytes: number) {
    let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
    let index = 0;
    for (index; bytes > 1024; index++) {
      bytes /= 1024;
    }
    return parseFloat(bytes.toFixed(0)) + ' ' + units[index];
  }

}

export type VoyageUnion = BargeVoyage | RoadVoyage | RailVoyage;
export type VoyageDataUnion = BargeVoyageData | RoadVoyageData | RailVoyageData;

function emptyOrValue(value: string, prefix?: string, suffix?: string) {
  if (value === null) {
    return '';
  }
  let concatted = '';
  if (prefix !== undefined) {
    concatted += prefix;
  }
  concatted += value;
  if (suffix !== undefined) {
    concatted += suffix;
  }
  return concatted;
}

export const voyageComparator = new ComparatorChain((v1: Voyage, v2: Voyage) => {
  const s1 = v1.voyageStatus, s2 = v2.voyageStatus;
  if (s1 !== s2) {
    if (s1 === 'COMPLETED') {
      return 1;
    }
    if (s2 === 'COMPLETED') {
      return -1;
    }
    if (s1 === 'IN_OPERATION') {
      return 1;
    }
    if (s2 === 'IN_OPERATION') {
      return -1;
    }
  }
  return 0;
}, "voyageData.eta", "voyageData.firstDepartureDate").compare;

export type HandlingStatus =
  'stuck'
  | 'declared'
  | 'rejected'
  | 'acceptedWithWarnings'
  | 'accepted'
  | 'inOperation'
  | 'handled'
  | 'cancelled'
  | 'unknown';

export interface TerminalModel extends Terminal {
  displayName: string
}

export interface HandlingModel extends DetachedHandling, AttachedHandling {
  status: HandlingStatus;
  terminal: Terminal;
  visitArrived: boolean;
  visitNeverAcknowledged: boolean;
  visitDeclarationsMade: boolean;
  visitId: string;
  visitStatus: VisitStatus;
  voyageId: string;
  voyageStatus: VoyageStatus;
  voyageData: VoyageData | VoyageDataUnion;
  modality: Modality;
  eta: string;
  etd: string;
  visitData: VisitData;
  requestedVisitData: VisitData;
  new: boolean;
  copy: boolean;
  cutOffInMinutes: number;
  visitLoadDischargeListStatus: LoadDischargeListStatus;
  visitWaitForCloseVisitEnabled: boolean;
  declarant: HinterlandOrganisation;
  cargoDeclarants: HinterlandOrganisation[];
  // Road Only
  tripNumber: string;
  remainOnTruckContainers: string[];
  worstVisitHandlingStatus: HandlingStatus;
  worstVoyageHandlingStatus: HandlingStatus;
  worstVoyageVisitEta: string;
  worstVoyageEquipmentNumber: string;
}

export interface Organisation {
  shortName: string;
  organisationData: HinterlandOrganisation;
  roles: string[];
  preferences: OrganisationPreferences;
}
