import {Injectable} from "@angular/core";

@Injectable()
export class EventGateway {
  private handlers = [];

  publish(type: EventType | string, payload: any = null) {
    let handlerFunctionName = Object.values(EventType).includes(type) ? 'on' + EventType[type] : type;
    // console.debug("Calling handlerFunction: " + handlerFunctionName);
    this.handlers.filter(h => h[handlerFunctionName]).forEach(h => h[handlerFunctionName](payload));
  }

  registerLocalHandler(handler: any): () => void {
    this.handlers.push(handler);
    return () => this.handlers = this.handlers.filter(o => o !== handler);
  }

  registerLocalHandlers(...handlers): () => void {
    const subscriptions: any[] = handlers.map(h => this.registerLocalHandler(h));
    return () => subscriptions.forEach(s => s());
  }

}

export type EventHandler<T extends EventType> = (data: any) => void;

export enum EventType {
  /* Action panel events*/
  EnterEditMode,
  EnterActionMode,
  ExitActionMode,
  ExitHandlingActionMode,
  StartAddingHandlingsToVisit,
  AddHandlingToVisit,
  StartAttachingToVisit,
  AttachHandlingToVisit,

  /* Selection events */
  HandlingSelected,
  HandlingDeselected,

  SelectAllHandlings,
  DeselectAllHandlings,
  SelectAllHandlingsChanged,

  /* Websocket update/patch events */
  UpdateVoyage,
  PatchVoyage,
  UpdateDetachedHandling,
  PatchDetachedHandling,
  UpdateShipment,

  /* Applied patch events */
  PatchApplied,
  VoyageUpdated,
  VisitUpdated,
  HandlingUpdated
}
