<ngb-datepicker #picker (dateSelect)="onDateSelection($event)" [dayTemplate]="day" [displayMonths]="this.displayMonths" outsideDays="hidden">
</ngb-datepicker>

<ng-template #day let-date let-focused="focused">
	<span (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null"
        [class.faded]="isHovered(date) || isInside(date)"
        [class.focused]="focused"
        [class.range]="isRange(date)"
        [class.start]="isStart(date)"
        [class.today]="isToday(date)"
        [class.single]="isSingle(date)"
        [class.end]="isEnd(date)"
        class="custom-day">
		{{ date.day }}
	</span>
</ng-template>
