import {Injectable} from '@angular/core';
import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';

@Injectable()
export class NlDateParserFormatter extends NgbDateParserFormatter {
  format(date: NgbDateStruct): string {
    if (!date) {
      return null;
    }
    const m = moment({ y : date.year, M : date.month - 1, d : date.day});
    if (!m.isValid()) {
      return null;
    }
    return m.format("DD-MM-YYYY");
  }

  parse(value: string): NgbDateStruct {
    if (!value) {
      return null;
    }
    const m = moment(value, "DD-MM-YYYY");
    if (!m.isValid()) {
      return null;
    }
    return {year: m.year(), month: m.month() + 1, day: m.date()} ;
  }
}
