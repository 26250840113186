import { NgIf } from "@angular/common";
import { Component } from "@angular/core";

import { AppContext } from "../../../app-context";
import { MainNavbarLeftItemComponent } from "../main-navbar-left-item/main-navbar-left-item.component";

@Component({
  selector: "app-main-navbar-left",
  templateUrl: "./main-navbar-left.component.html",
  styleUrls: ["./main-navbar-left.component.scss"],
  standalone: true,
  imports: [MainNavbarLeftItemComponent, NgIf],
})
export class MainNavbarLeftComponent {
  appContext = AppContext;
}
