import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import {IconButtonComponent} from "../icon-button/icon-button.component";

export type AlertColor = 'success' | 'warning' | 'error' | 'info';

@Component({
  selector: 'app-alert',
  standalone: true,
  imports: [IconButtonComponent],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss',
})
export class AlertComponent {
  @Input() embedded = false;
  @Input() color: AlertColor = 'success';

  @Input({ required: true }) alertTitle!: string;
  @Input({ required: true }) alertMessage!: string;

  @Output() dismissAction = new EventEmitter<void>();

  @HostBinding('class')
  get alertClass(): string[] {
    return [this.color, this.embedded ? 'embedded' : ''];
  }
}
