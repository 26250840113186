import { Injectable, Type } from '@angular/core';
import { Subject } from 'rxjs';

import { EditModalEvent } from './edit-modal.component';

export interface ModalData<T> {
  data: T
}

/**
 * Service to open and close the edit modal
 * Usage:
 *     1. Inject the service in the component where you want to open the modal.
 *     2. Call the openModal method with the EditModalEvent as parameter.
 *     3. The modal will open with the component specified in the EditModalEvent.
 *
 * NOTE: The component specified in the EditModalEvent must be declared in the module where the modal is used.
 */
@Injectable({
    providedIn: 'root'
})
export class EditModalService {

  private modalData = new Subject<EditModalEvent | null>();
  modalData$ = this.modalData.asObservable();

  /**
   * Opens an edit modal with the specified component and optional input data.
   */
  openModal<T extends ModalData<object>>(component: Type<T>, data?: T['data']) {
    this.modalData.next({ component, data });
  }

  closeModal() {
    this.modalData.next(null);
  }

}
