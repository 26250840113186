<app-offcanvas panelTitle="Notification Settings">
  <div class="nav-tabs-content nav-tabs pb-5">
    <div class="tab-pane">
      <div class="nav-content">

        <app-error [errors]="errors"></app-error>

        <div class="inner-section-header">User notifications</div>

        <div class="inner-sub-section-header">Handlings</div>

        <app-form-field [renderOptional]="false">
          <label>Pre-notification is accepted</label>
          <input type="text" class="form-control lg" placeholder="Email address"
                 [(ngModel)]="this.userPreferences.containerStatusAcceptEmail">
        </app-form-field>

        <app-form-field [renderOptional]="false">
          <label>Pre-notification is not accepted</label>
          <input type="text" class="form-control lg" placeholder="Email address"
                 [(ngModel)]="this.userPreferences.containerStatusNotAcceptEmail">
        </app-form-field>

        <app-form-field [renderOptional]="false">
          <label>Pre-notification is rejected</label>
          <input type="text" class="form-control lg" placeholder="Email address"
                 [(ngModel)]="this.userPreferences.containerStatusRejectEmail">
        </app-form-field>

        <app-form-field [renderOptional]="false" tooltip="You will receive an email when a forwarder authorises you for a shipment and includes transport order details.">
          <label>Transport order is received</label>
          <input type="text" class="form-control lg" placeholder="Email address"
                 [(ngModel)]="this.userPreferences.transportOrderReceivedEmail">
        </app-form-field>

        <app-form-field [renderOptional]="false" tooltip="You will receive an email when a forwarder authorises you in the secure chain for a shipment and gives the permission to prenotify the container at the terminal">
          <label>Authorised for shipment in the secure chain</label>
          <input type="text" class="form-control lg" placeholder="Email address"
                 [(ngModel)]="this.userPreferences.shipmentNominationEmail">
        </app-form-field>

        <app-form-field [renderOptional]="false" tooltip="You will receive an email when a forwarder un-authorises you in the secure chain for a shipment and therefore you do not have the permission to prenotify the container at the terminal You will receive an email when a forwarder un-authorises you in the Secure Chain for a shipment and therefore you do not have the permission to prenotify the container at the terminal">
          <label>Un-authorised for shipment in the secure chain</label>
          <input type="text" class="form-control lg" placeholder="Email address"
                 [(ngModel)]="this.userPreferences.shipmentNominationRevokedEmail">
        </app-form-field>

        <div class="inner-sub-section-header">Visits</div>

        <app-form-field [renderOptional]="false">
          <label>Visit is accepted</label>
          <input type="text" class="form-control lg" placeholder="Email address"
                 [(ngModel)]="this.userPreferences.visitAcceptedEmail">
        </app-form-field>

        <app-form-field [renderOptional]="false">
          <label>Update of visit is accepted</label>
          <input type="text" class="form-control lg" placeholder="Email address"
                 [(ngModel)]="this.userPreferences.visitUpdateAcknowledgedEmail">
        </app-form-field>

        <app-form-field [renderOptional]="false">
          <label>Visit is rejected</label>
          <input type="text" class="form-control lg" placeholder="Email address"
                 [(ngModel)]="this.userPreferences.visitRejectedEmail">
        </app-form-field>

        <app-form-field [renderOptional]="false">
          <label>Visit is cancelled</label>
          <input type="text" class="form-control lg" placeholder="Email address"
                 [(ngModel)]="this.userPreferences.visitCancelledEmail">
        </app-form-field>
      </div>
    </div>
  </div>

  <ng-container buttonBar>
    <button app-button (click)="updateNotificationSettings()">Save</button>
  </ng-container>
</app-offcanvas>
